var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"decalsOverview",staticClass:"decals_overview_pdf",staticStyle:{"position":"absolute"}},[_c('div',{staticClass:"canvas_frame",style:({
      width: _vm.parameters[_vm.currentView].width,
      height: _vm.parameters[_vm.currentView].height || _vm.parameters[_vm.currentView].width,
      marginTop: _vm.parameters[_vm.currentView].top,
      marginLeft: _vm.parameters[_vm.currentView].left,
      marginRight: _vm.parameters[_vm.currentView].right ? _vm.parameters[_vm.currentView].right : '0'
    })},[(_vm.pngBase)?_c('img',{style:({ width: `${_vm.isUnderwear ? '130%' : '100%'}` }),attrs:{"src":_vm.pngBase}}):_vm._e()]),(_vm.currentView === 'front')?_c('div',{staticClass:"leg_frame",style:({
      width: _vm.parameters['legs'].width,
      height: _vm.parameters['legs'].height || _vm.parameters['legs'].width,
      marginTop: _vm.parameters['legs'].top,
      marginLeft: _vm.parameters['legs'].left,
      marginRight: _vm.parameters['legs'].right ? _vm.parameters['legs'].right : '0'
    })},[(_vm.exceptionPNG)?_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.exceptionPNG}}):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }