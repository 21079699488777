<template>
  <div class="decals-mask" style="opacity:0">
    <object :data="mask" ref="viewMask"></object>
  </div>
</template>
<script>
export default {
  computed: {
    mask() {
      return this.$store.state.configurator.actualDecalArea && this.$store.state.configurator.actualDecalArea.mask
        ? this.$store.state.configurator.actualDecalArea.mask
        : ''
    }
  },
  props: {
    transformation: {
      type: Object
    }
  },
  methods: {
    setTransform() {
      const container = this.$refs.viewMask.getSVGDocument()
      const element = container && container.querySelector('svg')
      if (element) {
        element.style.transform = ''
        if (this.transformation && this.transformation.scale) {
          element.style.transform += `scale(${this.transformation.scale})`
        } else {
          element.style.transform += 'scale(1)'
        }
        if (this.transformation && this.transformation.position) {
          element.style.transform += `translate(${this.transformation.position.x}vh, ${
            this.transformation.position.y
          }vh)`
        } else {
          element.style.transform += 'translate(0,0)'
        }
      }
    }
  },
  mounted() {
    this.$refs.viewMask.addEventListener('load', () => {
      this.setTransform()
    })
  }
}
</script>

<style scoped lang="scss">
.decals-mask {
  transition: opacity 0.5s;
  pointer-events: none !important;

  object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none !important;
  }
}
</style>
