<template>
  <div :style="{ width: canvasWidth + 'px', height: canvasHeight + 'px' }" id="decals_component">
    <canvas id="decals"></canvas>
  </div>
</template>

<script>
//PROBLEMA: il prezzo varia perchè la scala viene approssimata da fabric js
var _self

export default {
  props: ['canvasHeight', 'canvasWidth'],
  data() {
    return {
      viewCanvas: null,
      standardWidth: 500,
      standardHeight: 500
    }
  },
  computed: {
    isUpdate() {
      return this.$store.getters.getDecalsStatus.updated
    }
  },
  watch: {
    isUpdate(newVal, oldVal) {
      if (newVal !== oldVal && newVal === false) {
        //in base al tipo di notify...
        switch (this.$store.getters.getDecalsStatus.type) {
          case 'add':
            var pl = this.$store.state.configurator.decalsStatus.payload
            this.addImage(pl.name, pl.src)
            break
          case 'remove':
            this.removeImage(this.$store.state.configurator.decalsStatus.payload)
            break
          case 'update':
            this.export()
            this.reset()
            this.$store.commit('CONFIGURATOR_SELECT_DECAL_AREA', this.$store.state.configurator.decalsStatus.payload)
            //aspetto qualche secondo prima di visualizzare i loghi (questo per dare tempo alla view di cambiare)
            setTimeout(() => {
              this.import()
              this.$store.commit('CONFIGURATOR_ISUPDATED')
            }, 600)
            break
          default:
            break
        }
      }
    }
  },
  methods: {
    addImage(imageName, uri) {
      //creo l'immagine
      var imgObj = new Image()
      //inserisco la src (todo: prima controllo se ha il mimetype incluso o meno)
      imgObj.src = uri
      //quando l'immagine è pronta
      imgObj.onload = function() {
        //creo l'immagine fabric
        const scaleVal = (_self.viewCanvas.width * 0.3) / Math.max(imgObj.width, imgObj.height)
        var image = new fabric.Image(imgObj, {
          angle: 0,
          padding: 0,
          borderColor: '#ffcf00',
          cornerColor: '#ffcf00',
          transparentCorners: false,
          cornerSize: 12,
          scaleX: (scaleVal * _self.viewCanvas.width) / _self.standardWidth,
          scaleY: (scaleVal * _self.viewCanvas.height) / _self.standardHeight
        })
        //estendo l'oggetto con il nome e l'id
        image.toObject = (function(toObject) {
          return function() {
            return fabric.util.object.extend(toObject.call(this), {
              name: this.name,
              id: this.id
            })
          }
        })(image.toObject)
        //aggiungo il nome
        image.name = imageName
        //aggiungo l'id
        image.id = generateID().toString()

        //Faccio l'update dello stato (pattern obs). aggiungo nel modello dati
        _self.$store.commit('CONFIGURATOR_ADD_DECAL', {
          id: image.id,
          name: imageName,
          src: uri,
          cm2: 0
        })

        //render props
        _self.viewCanvas.add(image)
        _self.viewCanvas.centerObject(image)
        _self.viewCanvas.renderAll()
        _self.export()
      }
    },
    removeImage(id) {
      var objects = _self.viewCanvas.getObjects()
      for (const key in objects) {
        if (objects[key].id === id) {
          _self.viewCanvas.remove(objects[key])
          _self.$store.commit('CONFIGURATOR_REMOVE_DECAL', id)
          _self.export()
          return
        }
      }
    },
    reset() {
      _self.viewCanvas.clear()
    },
    export() {
      this.$store.commit('CONFIGURATOR_SAVE_CONFIG', {
        canvas: { width: _self.viewCanvas.width, height: _self.viewCanvas.height },
        objects: _self.viewCanvas.toJSON(['id', 'name'])
      })
    },
    import() {
      if (
        this.$store.state.configurator.actualDecalArea.config &&
        this.$store.state.configurator.actualDecalArea.config.objects
      ) {
        //resize dei loghi
        let scaleX = _self.viewCanvas.width / this.$store.state.configurator.actualDecalArea.config.canvas.width
        let scaleY = _self.viewCanvas.height / this.$store.state.configurator.actualDecalArea.config.canvas.height
        var objects = this.$store.state.configurator.actualDecalArea.config.objects.objects
        for (let i in objects) {
          objects[i].scaleX = objects[i].scaleX * scaleX
          objects[i].scaleY = objects[i].scaleY * scaleY
          objects[i].left = objects[i].left * scaleX
          objects[i].top = objects[i].top * scaleY
          //per sicurezza carico la src dal modello
          objects[i].src = this.$store.state.configurator.actualDecalArea.logos.find(
            elem => elem.id === objects[i].id
          ).src

          // style
          objects[i].padding = 0
          objects[i].borderColor = '#ffcf00'
          objects[i].cornerColor = '#ffcf00'
          objects[i].cornerSize = 12
          objects[i].transparentCorners = false
        }
        _self.viewCanvas.loadFromJSON(this.$store.state.configurator.actualDecalArea.config.objects)
      }
    },
    onResize() {
      const mainViewArea = document.querySelector('.main-view-area')
      const mainView = document.querySelector('.main-view')
      mainView.style.height = `${mainView.clientWidth}px`

      const centeringTop = mainViewArea.clientHeight - mainView.clientHeight
      mainView.style.marginTop = `${centeringTop / 2}px`

      let newWidth = mainView.clientWidth
      let newHeight = mainView.clientHeight
      if (_self.viewCanvas.width != newWidth || _self.viewCanvas.height != newHeight) {
        let scaleMultiplierX = newWidth / _self.viewCanvas.width
        let scaleMultiplierY = newHeight / _self.viewCanvas.height
        let objects = _self.viewCanvas.getObjects()
        for (let i in objects) {
          objects[i].scaleX = objects[i].scaleX * scaleMultiplierX
          objects[i].scaleY = objects[i].scaleY * scaleMultiplierY
          objects[i].left = objects[i].left * scaleMultiplierX
          objects[i].top = objects[i].top * scaleMultiplierY
          objects[i].setCoords()
        }
        var obj = _self.viewCanvas.backgroundImage
        if (obj) {
          obj.scaleX = obj.scaleX * scaleMultiplierX
          obj.scaleY = obj.scaleY * scaleMultiplierY
        }
        _self.viewCanvas.discardActiveObject()
        _self.viewCanvas.setWidth(newWidth)
        _self.viewCanvas.setHeight(newHeight)
        _self.viewCanvas.renderAll()
        _self.viewCanvas.calcOffset()
      }
    },
    updateLogoArea(opt) {
      let cm2Logo =
        (this.$store.state.configurator.actualDecalArea.cm2 / (this.viewCanvas.width * this.viewCanvas.height)) *
        (opt.width * opt.height * opt.scaleX * opt.scaleY)
      this.$store.commit('CONFIGURATOR_UPDATE_CM2', {
        id: opt.id,
        cm2: cm2Logo
      })
    }
  },
  mounted() {
    const container = document.querySelector('.main-view')
    _self.viewCanvas = new fabric.Canvas('decals', {
      width: container.clientWidth,
      height: container.clientHeight
    })
    //resize
    window.addEventListener('resize', this.onResize)
    window.dispatchEvent(new Event('resize'))

    //gestisco gli eventi del canvas
    this.viewCanvas.on('object:added', opt => {
      this.updateLogoArea(opt.target)
    })
    this.viewCanvas.on('object:modified', opt => {
      this.updateLogoArea(opt.target)
      this.export()
    })

    //import
    if (this.$store.state.configurator.actualDecalArea) {
      this.import()
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    //export
    if (this.$store.state.configurator.actualDecalArea) {
      this.export()
    }
  },
  created() {
    _self = this
  }
}

/* PRIVATE */
//metodo che genera un id univoco in base al tempo in cui entra
function generateID() {
  const id = Date.now()
  const oggettiInCanvas = _self.viewCanvas.toJSON().objects
  oggettiInCanvas.forEach(oggetto => {
    //se esiste già l'id (cosa praticamente impossibile)
    if (oggetto.id === id) {
      return generateID()
    }
  })
  return id
}
</script>
<style scoped>
#decals_component {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
