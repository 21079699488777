<template>
  <div class="decals_overview_pdf" ref="decalsOverview" style="position: absolute;">
    <div
      class="canvas_frame"
      :style="{
        width: parameters[currentView].width,
        height: parameters[currentView].height || parameters[currentView].width,
        marginTop: parameters[currentView].top,
        marginLeft: parameters[currentView].left,
        marginRight: parameters[currentView].right ? parameters[currentView].right : '0'
      }"
    >
      <img v-if="pngBase" :src="pngBase" :style="{ width: `${isUnderwear ? '130%' : '100%'}` }" />
    </div>
    <!-- nel caso fossi nel front -->
    <div
      v-if="currentView === 'front'"
      class="leg_frame"
      :style="{
        width: parameters['legs'].width,
        height: parameters['legs'].height || parameters['legs'].width,
        marginTop: parameters['legs'].top,
        marginLeft: parameters['legs'].left,
        marginRight: parameters['legs'].right ? parameters['legs'].right : '0'
      }"
    >
      <img v-if="exceptionPNG" :src="exceptionPNG" style="width:100%" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    currentView: String
  },
  data() {
    return {
      pngBase: '',
      exceptionPNG: ''
    }
  },
  methods: {
    renderPNG() {
      let areaLoghi = this.canvasConfig[0]
      //se esiste la config
      if (areaLoghi && areaLoghi.config && areaLoghi.config.canvas && areaLoghi.config.objects.objects.length > 0) {
        let canvas = document.createElement('canvas')
        let fabricCanvas = new fabric.Canvas(canvas, {
          width: 566.9291338,
          height: 566.9291338
        })
        var scaleMultiplierX = 566.9291338 / areaLoghi.config.canvas.width
        var scaleMultiplierY = 566.9291338 / areaLoghi.config.canvas.height
        let objects = JSON.parse(JSON.stringify(areaLoghi.config.objects.objects))
        for (let i in objects) {
          objects[i].scaleX = objects[i].scaleX * scaleMultiplierX
          objects[i].scaleY = objects[i].scaleY * scaleMultiplierY
          objects[i].left = objects[i].left * scaleMultiplierX
          objects[i].top = objects[i].top * scaleMultiplierY
          objects[i].selectable = false
        }
        fabricCanvas.loadFromJSON({ objects: objects, version: areaLoghi.config.version }, () => {
          this.pngBase = fabricCanvas.toDataURL('png')
        })
      } else this.pngBase = ''
      if (this.canvasConfig[1]) this.legPNG()
    },
    legPNG() {
      let areaLoghi = this.canvasConfig[1]
      //se esiste la config
      if (areaLoghi && areaLoghi.config && areaLoghi.config.canvas && areaLoghi.config.objects.objects.length > 0) {
        let canvas = document.createElement('canvas')
        let fabricCanvas = new fabric.Canvas(canvas, {
          width: 500,
          height: 500
        })
        var scaleMultiplierX = 500 / areaLoghi.config.canvas.width
        var scaleMultiplierY = 500 / areaLoghi.config.canvas.height
        let objects = JSON.parse(JSON.stringify(areaLoghi.config.objects.objects))
        for (let i in objects) {
          objects[i].scaleX = objects[i].scaleX * scaleMultiplierX
          objects[i].scaleY = objects[i].scaleY * scaleMultiplierY
          objects[i].left = objects[i].left * scaleMultiplierX
          objects[i].top = objects[i].top * scaleMultiplierY
          objects[i].selectable = false
        }
        fabricCanvas.loadFromJSON({ objects: objects, version: areaLoghi.config.version }, () => {
          this.exceptionPNG = fabricCanvas.toDataURL('png')
        })
      } else this.exceptionPNG = ''
    },
    handleResize() {
      /* const legWidth = document.getElementById('polsicaviglie').offsetWidth
      const legheight = document.getElementById('polsicaviglie').offsetHeight
      console.warn('legWidth ', legWidth)
      console.warn('legheight ', legheight) */
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.renderPNG()
    })
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    currentModel() {
      return this.$store.state.configurator.actualModel
    },
    currentStyle() {
      return this.$store.state.configurator.actualStyle
    },
    canvasConfig() {
      return this.$store.state.configurator.actualModel
        ? this.$store.state.configurator.actualModel.styles[
            this.$store.state.configurator.actualStyle
          ].customizedLogo.filter(elem => elem.view === this.currentView)
        : []
    },
    isUnderwear() {
      return this.currentModel.type && this.currentModel.type === 'underwear'
    },
    parameters() {
      return this.isUnderwear
        ? {
            front: {
              width: '65mm',
              height: '85mm',
              top: '21mm',
              left: '7.5mm'
            },
            back: {
              width: '65mm',
              height: '88mm',
              top: '17.5mm',
              left: '7.5mm'
            },
            left: {
              width: '60mm',
              height: '89mm',
              top: '24.5mm',
              left: '11mm'
            },
            right: {
              width: '60mm',
              height: '89mm',
              top: '14.5mm',
              left: '1mm'
            },
            legs: {
              width: '0mm',
              top: '0mm',
              left: '0mm'
            }
          }
        : {
            front: {
              width: '75mm',
              height: '60mm',
              top: '-7mm',
              left: '10mm'
            },
            back: {
              width: '75mm',
              top: '-9mm',
              left: '10mm'
            },
            left: {
              width: '75mm',
              top: '1mm',
              left: '10mm'
            },
            right: {
              width: '75mm',
              top: '0mm',
              left: '0.5mm'
            },
            legs: {
              width: '135mm',
              height: '120mm',
              top: '-11mm',
              left: '-20mm'
            }
          }
    }
  },
  watch: {
    currentModel() {
      this.renderPNG()
    },
    currentStyle() {
      this.renderPNG()
    },
    currentView() {
      this.$nextTick(() => {
        this.renderPNG()
      })
    },
    canvasConfig: {
      deep: true,
      handler() {
        this.renderPNG()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.decals_overview_pdf {
  width: 100%;
  height: 100%;
  pointer-events: none !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.leg_frame {
  img {
    object-fit: none;
  }
}
/* 
.canvas_frame,
.leg_frame {
} */
</style>
