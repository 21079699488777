var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"design"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('h2',[_vm._v(_vm._s(_vm.$t('CFG_DESIGN_TITLE_MODEL')))])])]),_c('div',{staticClass:"row"},_vm._l((_vm.$store.state.configurator.models),function(model,index){return _c('div',{key:index,staticClass:"col-md-3 model no-select",class:{ 'cursor-pointer': model.sku !== _vm.$store.state.configurator.actualModel.sku },on:{"click":function($event){return _vm.selectModel(model.sku)}}},[_c('div',{staticClass:"square-container"},[_c('div',{staticClass:"square-content"},[_c('SVGViewer',{staticClass:"py-2",attrs:{"viewData":{
              asset: model.preview.views.front,
              areas: model.preview.areas,
              transformation: null
            },"nascar":_vm.configuratorNascarCuffs,"selected":model.sku === _vm.$store.state.configurator.actualModel.sku,"belt":_vm.configuratorBelt.show,"beltArea":model.preview.beltArea,"pocket":_vm.configuratorPocket}})],1)]),_c('p',{staticClass:"text-center mt-2 mb-3",class:{ 'text-active': model.sku === _vm.$store.state.configurator.actualModel.sku }},[_vm._v(" "+_vm._s(model.name)+" ")])])}),0),(_vm.$store.state.configurator.actualModel.description)?_c('div',{staticClass:"row mt-3 mt-xl-2"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"model-description"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(_vm.$store.state.configurator.actualModel.description))}}),(
            (!_vm.$route.meta.b2c && _vm.loggedUser && _vm.loggedUser.hideDescrPrice !== true) ||
              (_vm.$route.meta.b2c && _vm.b2cDealer && _vm.b2cDealer.hideDescrPrice !== true)
          )?_c('span',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t('CFG_DESIGN_PRICE_FROM'))+" "+_vm._s(_vm._f("price")(_vm.$store.state.configurator.basePrice[_vm.userCompany])))]):_vm._e()])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }