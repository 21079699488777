var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"decals_overview",class:_vm.isUnderwear ? 'underwear' : ''},[(_vm.images.upper)?_c('div',{staticClass:"decals",class:_vm.isUnderwear ? 'underwear' : '',style:({
      height: `${_vm.isUnderwear ? _vm.imgHeight : _vm.parameters[_vm.currentView].height + '%'}`,
      width: `${_vm.isUnderwear ? _vm.imgWidth : '100%'}`,
      backgroundImage: `url(${_vm.images.upper})`,
      marginTop: _vm.parameters[_vm.currentView].top + `${_vm.isUnderwear ? '%' : 'vh'}`,
      backgroundSize: `${_vm.isUnderwear ? 'cover' : 'contain'}`,
      backgroundPosition: `${_vm.isUnderwear ? 'center' : 'top center'}`
    })}):_vm._e(),(_vm.currentView === 'front' && _vm.images.lower)?_c('div',{staticClass:"decals",style:({
      position: 'absolute',
      bottom: '0',
      height: '63%',
      transform: 'scale(1.33)',
      backgroundSize: 'contain !important',
      backgroundPosition: 'top center !important',
      backgroundImage: `url(${_vm.images.lower})`
    })}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }