<template>
  <div class="section-container h-100">
    <!-- parte destra -->
    <div class="row h-100">
      <div class="col-md-6 h-100" id="left-side">
        <img class="ceasy-logo-error img-fluid mt-3" src="@/assets/logo_customeasy_oriz.png" />

        <div class="B2C_thank-you row h-100 justify-content-center align-items-center">
          <div class="col-10 col-xl-8">
            <div class="row">
              <div class="col">
                <p class="dealer-name" v-if="!b2cDealer.logo">
                  {{ b2cDealer.name }}
                </p>
                <img class="img-fluid" v-if="b2cDealer.logo" :src="b2cDealer.logo" />
              </div>
            </div>
            <div class="row">
              <div class="col-10">
                <h1>{{ $t('CFG_B2C_THANK_YOU_TITLE') }}</h1>
                <p>{{ $t('CFG_B2C_THANK_YOU_TEXT') }}</p>
              </div>
              <div class="col-2 text-right check-icon">
                <Check />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- parte sinistra -->
      <div class="col-md-6 config-section h-100">
        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-6 d-flex flex-wrap justify-content-center align-items-center">
            <a
              class="start-btn Button Button__create"
              :href="$router.resolve({ name: 'B2C_configurator', params: { token: $route.params.token } }).href"
            >
              RESTART
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Check from 'vue-material-design-icons/Check.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'b2cThankYou',
  components: {
    Check
  },
  computed: {
    ...mapGetters(['b2cDealer'])
  },
  mounted() {
    this.$store.commit('CONFIGURATOR_SKIP_NAVIGATION_ALERT', true)
  }
}
</script>

<style scoped lang="scss">
.B2C_thank-you {
  color: $c-blue;
  font-size: 30px;
  .check-icon {
    font-size: 70px;
    color: $c-yellow;
  }
  p {
    font-size: 20px;
  }
  .dealer-name {
    font-size: 50px;
    color: $c-blue;
    text-transform: capitalize;
  }
}

.ceasy-logo-error {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 50%;
  max-width: 350px;
}
</style>
