<template>
  <div class="summary">
    <div class="row">
      <div class="col-6">
        <!-- MODEL -->
        <div class="row mt-3">
          <div class="col-6 summary-tab-key">{{ $t('CFG_SUMMARY_TABLE_MODEL') }}</div>
          <div class="col-6 summary-tab-value text-right">{{ $store.state.configurator.actualModel.name }}</div>
        </div>
        <div class="separator-line" />
        <div
          class="row mt-3"
          v-if="
            $store.state.configurator.actualModel.homologation &&
              $store.state.configurator.actualModel.homologation.length > 0
          "
        >
          <div class="col-12 summary-tab-key">{{ $t('CFG_SUMMARY_HOMOLOGATION') }}</div>
          <div
            class="col-12 summary-tab-value"
            v-for="(h, idx) in $store.state.configurator.actualModel.homologation"
            :key="'homologation-' + idx"
          >
            {{ h }}
          </div>
        </div>
        <div
          class="separator-line"
          v-if="
            $store.state.configurator.actualModel.homologation &&
              $store.state.configurator.actualModel.homologation.length > 0
          "
        />
        <!-- STYLE -->
        <div class="row mt-3">
          <div class="col-6 summary-tab-key">{{ $t('CFG_SUMMARY_TABLE_STYLE') }}</div>
          <div class="col-6 summary-tab-value text-right">
            {{ $store.state.configurator.actualModel.styles[$store.state.configurator.actualStyle].name }}
          </div>
        </div>
        <!-- STYLE - pocket -->
        <div class="row" v-show="configuratorIsPocketAvailable">
          <div class="col-6">{{ $t('CFG_SUMMARY_TABLE_POCKET') }}</div>
          <div class="col-6 text-right" v-if="configuratorPocket">{{ $t('YES') }}</div>
          <div class="col-6 text-right" v-else>{{ $t('NO') }}</div>
        </div>
        <!-- STYLE - navigator pocket -->
        <div class="row" v-show="configuratorIsNavigatorPocketAvailable">
          <div class="col-6">{{ $t('CFG_SUMMARY_TABLE_NAVIGATOR_POCKET') }}</div>
          <div class="col-6 text-right" v-if="configuratorNavigatorPocket">{{ $t('YES') }}</div>
          <div class="col-6 text-right" v-else>{{ $t('NO') }}</div>
        </div>
        <!-- STYLE - belt -->
        <div class="row" v-show="configuratorIsBeltAvailable">
          <div class="col-6">{{ $t('CFG_SUMMARY_TABLE_BELT') }}</div>
          <div class="col-6 text-right" v-if="configuratorIsBeltAvailable && configuratorBelt && configuratorBelt.show">
            {{ $t('YES') }}
          </div>
          <div
            class="col-6 text-right"
            v-if="!configuratorIsBeltAvailable || !configuratorBelt || !configuratorBelt.show"
          >
            {{ $t('NO') }}
          </div>
        </div>
        <!-- STYLE - nascar -->
        <div class="row" v-show="configuratorAreNascarCuffsAvailable">
          <div class="col-6">{{ $t('CFG_SUMMARY_TABLE_NASCAR_CUFFS') }}</div>
          <div class="col-6 text-right" v-if="configuratorNascarCuffs">{{ $t('YES') }}</div>
          <div class="col-6 text-right" v-if="!configuratorNascarCuffs">{{ $t('NO') }}</div>
        </div>
        <div class="separator-line" />
        <!-- COLORS -->
        <div class="row mt-3 mb-2">
          <div class="col-12 summary-tab-key">{{ $t('CFG_SUMMARY_TABLE_COLORS') }}</div>
        </div>
        <div
          class="row"
          v-for="(area, index) in $store.state.configurator.actualModel.styles[$store.state.configurator.actualStyle]
            .areas"
          :key="index"
        >
          <div class="col-6">{{ $t(area.name) }}</div>
          <div class="col-6 text-right">{{ $t(area.selectedMaterial) }}</div>
        </div>
        <!-- TEXT -->
        <div class="separator-line" />
        <div class="row mt-3 mb-2">
          <div class="col-12 summary-tab-key">{{ $t('CFG_SUMMARY_TABLE_TEXT') }}</div>
        </div>
        <div class="row">
          <div class="col-6">{{ $t('CFG_SUMMARY_TABLE_TEXT_COUNTRY') }}</div>
          <div class="col-6 text-right">{{ getCountryName($store.state.configurator.actualModel.belt.country) }}</div>
        </div>
        <div class="row" v-if="userCompany !== 'USA'">
          <div class="col-6">{{ $t('CFG_SUMMARY_TABLE_TEXT_BLOOD') }}</div>
          <div class="col-6 text-right">{{ $store.state.configurator.actualModel.belt.bloodType || '-' }}</div>
        </div>
        <div class="row">
          <div class="col-6">{{ $t('CFG_SUMMARY_TABLE_TEXT_NAME') }}</div>
          <div class="col-6 text-right">{{ $store.state.configurator.actualModel.belt.name || '-' }}</div>
        </div>
        <div class="row">
          <div class="col-6">{{ $t('CFG_SUMMARY_TABLE_TEXT_COLOR') }}</div>
          <div class="col-6 text-right">
            {{
              $store.state.configurator.actualModel.belt.selectedMaterial
                ? $t($store.state.configurator.actualModel.belt.selectedMaterial)
                : '-'
            }}
          </div>
        </div>
        <div class="separator-line" />
        <!-- SIZE -->
        <div class="row mt-3 mb-2">
          <div class="col-12 summary-tab-key" :class="{ 'text-danger': validationError.size }">
            {{ $t('CFG_SUMMARY_TABLE_SIZE') }}
          </div>
        </div>
        <div class="row">
          <div class="col-6" v-if="$store.state.configurator.actualModel.size.actualSize.type === 'standard'">
            {{ $t('CFG_SUMMARY_TABLE_SIZE_STD') }}
            <span>({{ $t('CFG_SIZE_GENDER_MALE') }})</span>
          </div>
          <div class="col-12" v-if="$store.state.configurator.actualModel.size.actualSize.type === 'customized'">
            {{ $t('CFG_SUMMARY_TABLE_SIZE_CUSTOM') }}
            <span
              >({{
                $store.getters.actualSize.gender === 'F' ? $t('CFG_SIZE_GENDER_FEMALE') : $t('CFG_SIZE_GENDER_MALE')
              }})</span
            >
            <span v-if="$store.state.configurator.actualModel.size.actualSize.sizeYou">(SizeYou)</span>
          </div>
          <div class="col-6 text-right" v-if="$store.state.configurator.actualModel.size.actualSize.measure">
            {{ $store.state.configurator.actualModel.size.actualSize.measure }}
            <span v-if="$store.state.configurator.actualModel.size.actualSize.type === 'standard'">
              {{ $t('CFG_SIZE_STD_SUFFIX_' + $store.state.configurator.actualModel.size.actualSize.measure) }}
            </span>
          </div>
          <div class="col-12 invalid-msg mb-2" v-if="validationError.size">{{ $t(validationError.size) }}</div>
        </div>
        <div
          class="row mt-1"
          v-if="$store.state.configurator.actualModel.size.actualSize.type === 'customized' || userCompany === 'ITA'"
        >
          <div class="col-12 summary-tab-key" v-if="isCustomizedSize">
            <span v-if="$store.state.configurator.actualModel.size.actualSize.type === 'standard'">{{
              $t('CFG_SUMMARY_TABLE_SIZE_REGULATIONS')
            }}</span>
            <span v-if="$store.state.configurator.actualModel.size.actualSize.type === 'customized'">{{
              $t('CFG_SUMMARY_TABLE_SIZE_MEASURES')
            }}</span>
          </div>
        </div>
        <div
          v-if="$store.state.configurator.actualModel.size.actualSize.type === 'customized' || userCompany === 'ITA'"
        >
          <div
            class="row"
            v-for="(elem, i) in $store.state.configurator.actualModel.size.actualSize.measures"
            :key="elem.name + i"
          >
            <div
              class="col-8"
              v-if="$store.state.configurator.actualModel.size.actualSize.type !== 'standard' || elem.value !== 0"
            >
              {{ $t(elem.name) }}
            </div>
            <div
              class="col-4 text-right"
              v-if="$store.state.configurator.actualModel.size.actualSize.type === 'standard' && elem.value !== 0"
            >
              <span v-if="$store.state.configurator.actualModel.size.actualSize.type === 'standard' && elem.value > 0"
                >+</span
              >{{ elem.value }} {{ elem.unit[userCompany] }}
            </div>
            <div
              class="col-4 text-right"
              v-if="$store.state.configurator.actualModel.size.actualSize.type === 'customized'"
            >
              <span v-if="elem.value">{{ elem.value }} {{ elem.unit[userCompany] }}</span>
              <span v-if="!elem.value">-</span>
            </div>
          </div>
        </div>
        <div class="separator-line" v-if="$store.getters.getNumLogo > 0" />
        <!-- LOGOS -->
        <div class="row mt-3 mb-2" v-if="$store.getters.getNumLogo > 0">
          <div class="col-12 summary-tab-key">{{ $t('CFG_SUMMARY_TABLE_LOGOS') }}</div>
        </div>
        <div class="row" v-if="$store.getters.getNumLogo > 0">
          <div class="col-6">{{ $t('CFG_SUMMARY_TABLE_LOGOS_MODE') }}</div>
          <div class="col-6 text-right" v-if="$store.state.configurator.currentLogoMode === 0">
            {{ $t('CFG_SUMMARY_TABLE_LOGOS_MODE_PRINT') }}
          </div>
          <div class="col-6 text-right" v-if="$store.state.configurator.currentLogoMode === 1">
            {{ $t('CFG_SUMMARY_TABLE_LOGOS_MODE_EMBROIDERY') }}
          </div>
        </div>
        <div class="row" v-if="$store.getters.getNumLogo > 0">
          <div class="col-6">{{ $t('CFG_SUMMARY_TABLE_LOGOS_NUM') }}</div>
          <div class="col-6 text-right">{{ $store.getters.getNumLogo }}</div>
        </div>
        <div class="row" v-if="$store.getters.getNumLogo > 0 && userCompany !== 'USA'">
          <div class="col-6">{{ $t('CFG_SUMMARY_TABLE_LOGOS_CREDIT') }}</div>
          <div class="col-6 text-right">{{ $store.getters.getDecalCredits }}</div>
        </div>
      </div>
      <div class="col-6">
        <!-- ORDER QUANTITY -->
        <div class="row mt-3">
          <div class="col">
            <strong>{{ $t('CFG_SUMMARY_QUANTITY') }} <sup>*</sup></strong>
            <input
              type="number"
              v-model="orderModelData.quantity"
              class="mt-1 text-right summary-input quantity-input"
              :class="{ 'has-error': validationError.quantity }"
              @input="resetValidation('quantity')"
            />
            <div class="invalid-msg" v-if="validationError.quantity">{{ $t(validationError.quantity) }}</div>
          </div>
        </div>
        <!-- SIZEYOU USER -->
        <div class="row mt-4" v-if="$store.state.configurator.actualModel.size.actualSize.sizeYou">
          <div class="col">
            <strong>{{ $t('CFG_SUMMARY_SIZEYOU_USER') }}</strong>
            <input
              disabled
              class="mt-1 summary-input ref-input"
              type="text"
              v-model="$store.state.configurator.actualModel.size.actualSize.sizeYou"
            />
          </div>
        </div>
        <!-- ORDER REF -->
        <div class="row mt-4" v-if="!$route.meta.b2c">
          <div class="col">
            <strong>{{ $t('CFG_SUMMARY_REF') }}</strong>
            <input class="mt-1 summary-input ref-input" type="text" v-model="orderModelData.reference" />
          </div>
        </div>
        <!-- CONSUMER DATA -->
        <div class="row mt-2" v-if="$route.meta.b2c === true">
          <div class="col">
            <strong>{{ $t('CFG_SUMMARY_CONSUMER_NAME') }} <sup>*</sup></strong>
            <input
              class="mt-1 summary-input ref-input"
              :class="{ 'has-error': validationError.consumerName }"
              type="text"
              v-model="orderModelData.consumerName"
              @input="resetValidation('consumerName')"
            />
            <div class="invalid-msg" v-if="validationError.consumerName">{{ $t(validationError.consumerName) }}</div>
          </div>
        </div>
        <div class="row mt-2" v-if="$route.meta.b2c === true">
          <div class="col">
            <strong>{{ $t('CFG_SUMMARY_CONSUMER_EMAIL') }} <sup>*</sup></strong>
            <input
              class="mt-1 summary-input ref-input"
              :class="{ 'has-error': validationError.consumerEmail }"
              type="email"
              v-model="orderModelData.consumerEmail"
              @input="resetValidation('consumerEmail')"
            />
            <div class="invalid-msg" v-if="validationError.consumerEmail">{{ $t(validationError.consumerEmail) }}</div>
          </div>
        </div>
        <div class="row mt-2" v-if="$route.meta.b2c === true">
          <div class="col">
            <strong>{{ $t('CFG_SUMMARY_CONSUMER_PHONE') }} <sup>*</sup></strong>
            <input
              class="mt-1 summary-input ref-input"
              :class="{ 'has-error': validationError.consumerPhone }"
              type="text"
              v-model="orderModelData.consumerPhone"
              @input="resetValidation('consumerPhone')"
            />
            <div class="invalid-msg" v-if="validationError.consumerPhone">{{ $t(validationError.consumerPhone) }}</div>
          </div>
        </div>
        <!-- NOTES -->
        <div class="row mt-2">
          <div class="col">
            <strong>{{ $t('CFG_SUMMARY_NOTES') }}</strong>
            <textarea class="mt-1 summary-input notes-input" v-model="orderModelData.notes"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col mt-3 required-hint"><sup>*</sup> {{ $t('CFG_SUMMARY_B2C_REQUIRED_FIELDS') }}</div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row mt-4" v-if="!$route.meta.b2c">
              <div class="col-12 mb-2">
                <PriceCTA v-if="userCompany === 'USA'" />
              </div>
              <div class="col-12">
                <button class="Button Button__create" @click="save()">
                  <ContentSave /> {{ $t('CFG_SUMMARY_SAVE') }}
                </button>
              </div>
              <div class="col-12 mt-2">
                <button class="Button Button__create-outline" @click="saveAndExit()">
                  <ContentSave /> {{ $t('CFG_SUMMARY_SAVE_EXIT') }}
                </button>
              </div>
            </div>
            <div class="row mt-4" v-if="$route.meta.b2c">
              <div class="col-12 mb-2">
                <PriceCTA v-if="b2cDealer && b2cDealer.b2cPrice" />
              </div>
              <div class="col-12">
                <button
                  class="Button Button__create Button__auto-height"
                  :disabled="isB2cProcessing"
                  @click="saveB2C()"
                >
                  <span v-html="$t('CFG_SUMMARY_B2C_SEND')" />&nbsp;<Send />
                </button>
              </div>
              <b-modal
                id="b2cSaveOrderModal"
                ref="b2cSaveOrderModal"
                size="lg"
                centered
                no-close-on-backdrop
                no-close-on-esc
                hide-header
                hide-footer
              >
                <div class="row">
                  <div class="col-4 text-center pt-3 pb-3">
                    <Loader class="not-full-screen" />
                  </div>
                  <div class="col-8 pt-3 pb-3">
                    <p>
                      {{ $t('CFG_SUMMARY_B2C_SEND_PROCESS_TXT_1') }}
                    </p>
                    <p>
                      {{ $t('CFG_SUMMARY_B2C_SEND_PROCESS_TXT_2') }}
                    </p>
                    <p class="alert-txt"><Alert /> {{ $t('CFG_SUMMARY_B2C_SEND_PROCESS_TXT_3') }}</p>
                  </div>
                </div>
              </b-modal>
            </div>
            <div class="row">
              <div class="col">
                <div class="text-danger mt-4 text-center" v-if="Object.keys(validationError).length">
                  <Alert /> {{ $t('CFG_SUMMARY_SAVE_ERROR') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import countries from '@/assets/countries.json'
import { mapGetters } from 'vuex'
import validator from 'validator'
import ContentSave from 'vue-material-design-icons/ContentSave.vue'
import Send from 'vue-material-design-icons/Send.vue'
import Alert from 'vue-material-design-icons/Alert.vue'
import Loader from '@/components/Loader.vue'
import PriceCTA from '@/components/configurator/PriceCTA.vue'

export default {
  data() {
    return {
      validationError: {},
      orderModelData: {
        quantity: 1,
        reference: null,
        notes: null,
        consumerName: null,
        consumerEmail: null,
        consumerPhone: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'orderSelectedItem',
      'isCustomizedSize',
      'isSizeValid',
      'userCompany',
      'configuratorPocket',
      'configuratorNavigatorPocket',
      'configuratorIsPocketAvailable',
      'configuratorIsNavigatorPocketAvailable',
      'configuratorBelt',
      'configuratorIsBeltAvailable',
      'configuratorNascarCuffs',
      'configuratorAreNascarCuffsAvailable',
      'isB2cProcessing',
      'b2cDealer'
    ])
  },
  components: {
    ContentSave,
    Send,
    Alert,
    Loader,
    PriceCTA
  },
  props: {
    goBackHandler: {
      type: Function,
      required: true
    }
  },
  mounted() {
    this.orderModelData.quantity = this.orderSelectedItem.quantity
    this.orderModelData.reference = this.orderSelectedItem.reference
    this.orderModelData.notes = this.orderSelectedItem.notes
    this.orderModelData.consumerName = this.orderSelectedItem.consumerName
    this.orderModelData.consumerEmail = this.orderSelectedItem.consumerEmail
    this.orderModelData.consumerPhone = this.orderSelectedItem.consumerPhone
  },
  methods: {
    getCountryName(code) {
      const country = countries.filter(x => x.alpha3Code === code).pop()
      return country ? country.name : '-'
    },
    resetValidation(errorField) {
      this.validationError = Object.keys(this.validationError)
        .filter(x => x !== errorField)
        .reduce((res, x) => ({ ...res, [x]: this.validationError[x] }), {})
    },
    validation() {
      this.validationError = {}

      if (!Number.isInteger(Number(this.orderModelData.quantity)) || Number(this.orderModelData.quantity) <= 0) {
        this.validationError.quantity = 'CFG_SUMMARY_VALIDATION_ERROR_QTY'
      }
      if (this.$route.meta.b2c && !this.orderModelData.consumerName) {
        this.validationError.consumerName = 'CFG_SUMMARY_VALIDATION_ERROR_CONSUMER_NAME'
      }
      if (
        this.$route.meta.b2c &&
        (!this.orderModelData.consumerEmail || !validator.isEmail(this.orderModelData.consumerEmail))
      ) {
        this.validationError.consumerEmail = 'CFG_SUMMARY_VALIDATION_ERROR_CONSUMER_EMAIL'
      }
      if (
        this.$route.meta.b2c &&
        (!this.orderModelData.consumerPhone || !validator.isMobilePhone(this.orderModelData.consumerPhone))
      ) {
        this.validationError.consumerPhone = 'CFG_SUMMARY_VALIDATION_ERROR_CONSUMER_PHONE'
      }
      if (!this.isSizeValid) {
        this.validationError.size = 'CFG_SUMMARY_VALIDATION_ERROR_SIZE'
      }
    },
    saveConfiguration() {
      return new Promise(resolve => {
        this.validation()
        if (Object.keys(this.validationError).length > 0) {
          resolve(false)
        } else {
          this.$store.dispatch('ORDERS_UPDATE_SELECTED_ITEM', this.orderModelData)
          this.$nextTick(() => {
            this.$store.dispatch('CONFIGURATOR_EXPORT').then(data => {
              const pdfView = document.getElementById('pdf_gen')
              const pdfHtml = pdfView ? pdfView.innerHTML : ''
              this.$store
                .dispatch('ORDERS_UPDATE_CONFIGURATION', {
                  id: this.orderSelectedItem.id,
                  quantity: this.orderSelectedItem.quantity,
                  notes: this.orderSelectedItem.notes,
                  reference: this.orderSelectedItem.reference,
                  configuration: data,
                  pdf: window.btoa(unescape(encodeURIComponent(pdfHtml)))
                })
                .then(
                  () => resolve(true),
                  () => resolve(false)
                )
            })
          })
        }
      })
    },
    save() {
      this.saveConfiguration()
    },
    saveAndExit() {
      this.saveConfiguration().then(res => {
        if (res) {
          this.$store.commit('CONFIGURATOR_SKIP_NAVIGATION_ALERT', true)
          this.goBackHandler()
        }
      })
    },
    checkOrderCreated(callback) {
      // Check order if is already created in DB
      if (this.orderSelectedItem.id && this.orderSelectedItem.id > 0) {
        callback()
      } else if (this.$route.meta.b2c === true) {
        this.$store.dispatch('B2C_ORDER_CREATE', this.$route.params.token).then(() => {
          callback()
        })
      } else {
        this.$store.commit('ORDERS_ERROR')
      }
    },
    saveB2C() {
      this.validation()
      if (Object.keys(this.validationError).length === 0) {
        this.checkOrderCreated(() => {
          this.$store.dispatch('B2C_IS_PROCESSING')
          this.$refs.b2cSaveOrderModal.show()
          this.$store.dispatch('ORDERS_UPDATE_SELECTED_ITEM', this.orderModelData)
          this.$nextTick(() => {
            this.$store.dispatch('CONFIGURATOR_EXPORT').then(data => {
              const pdfView = document.getElementById('pdf_gen')
              const pdfHtml = pdfView ? pdfView.innerHTML : ''
              this.$store.dispatch('B2C_ORDER_UPDATE', {
                id: this.orderSelectedItem.id,
                token: this.$route.params.token,
                lang: this.$i18n.locale,
                quantity: this.orderSelectedItem.quantity,
                consumerName: this.orderSelectedItem.consumerName,
                consumerEmail: this.orderSelectedItem.consumerEmail,
                consumerPhone: this.orderSelectedItem.consumerPhone,
                notes: this.orderSelectedItem.notes,
                configuration: data,
                pdf: window.btoa(unescape(encodeURIComponent(pdfHtml)))
              })
            })
          })
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.summary {
  color: $c-blue;
  font-size: 16px;
  sup {
    font-size: 110%;
    top: -0.2em;
  }
  .required-hint {
    color: $c-grey3;
  }
  .summary-tab-key {
    font-weight: bold;
    text-transform: uppercase;
  }

  .separator-line {
    margin: 10px 0;
    border-top: 1px solid $c-blue;
  }
  .summary-input {
    color: $c-blue !important;
    display: block;
    background: transparent;
    border: 1px solid $c-blue !important;

    &.has-error {
      border-color: $c-error !important;
      color: $c-error !important;
    }
  }
  .invalid-msg {
    font-size: 90%;
    color: #dc3545;
  }
  .quantity-input {
    padding: 0 5px;
    width: 80px;
  }
  .notes-input {
    padding: 5px;
    outline: none;
    width: 100%;
    height: 100px;
  }
  .ref-input {
    padding: 5px;
    outline: none;
    width: 100%;
  }
  .Button {
    width: 100%;
    height: 50px !important;
    font-weight: bold !important;

    &__auto-height {
      height: auto !important;
      @include only-xl() {
        height: 50px !important;
      }
    }
  }
  .price-field {
    text-transform: uppercase;
  }
  .alert-txt {
    color: $c-grey3;
  }
}
</style>
