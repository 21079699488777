<template>
  <div class="configurator h-100 container-fluid">
    <PDFView
      :model="configuratorActualModel"
      :currentStyle="currentStyle"
      :areas="currentConfigurableStyleAreas"
      :order="orderSelectedItem"
      :dealer="loggedUser || b2cDealer"
      v-if="!ordersError && !configuratorError && configuratorActualModel && orderSelectedItem"
    />
    <!-- CONFIGURATOR -->
    <div
      class="section-container h-100"
      v-if="
        !ordersError &&
          !configuratorError &&
          !b2cThankYou &&
          configuratorActualModel &&
          $store.state.configurator.configState !== null
      "
    >
      <!-- parte destra -->
      <div class="row h-100">
        <div class="col-md-6 h-100" id="left-side">
          <div class="header">
            <div class="row h-100">
              <div class="col-9">
                <div class="row">
                  <div class="col-3 col-xl-2" v-if="!$route.meta.b2c">
                    <div id="exit" class="cursor-pointer" @click="goBack()">
                      <ChevronLeft /> <br />{{ $t('CFG_HEAD_EXIT') }}
                    </div>
                  </div>
                  <div class="col-9 col-xl-10">
                    <div id="logo-container" :class="{ noExit: $route.meta.b2c === true }">
                      <img class="text-center" src="@/assets/logo_customeasy_oriz.png" />
                    </div>
                    <span id="model-name" :class="{ noExit: $route.meta.b2c === true }">{{
                      $store.state.configurator.actualModel.name
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-3 pt-xl-3 text-right">
                <LanguageSwitch />
              </div>
            </div>
          </div>
          <div class="main-view-area" :class="{ 'full-height': !configuratorCurrentStep.viewsNavigation }">
            <div class="left-arrow" v-show="configuratorCurrentStep.viewsNavigation === true">
              <ChevronLeft @click.native="prevViewAngle()" />
            </div>
            <div class="main-view" :class="{ 'in-belt-step': $store.getters.isCurrentState('text') }">
              <SVGViewer
                :viewData="{
                  asset: configuratorCurrentView,
                  areas: currentConfigurableStyleAreas,
                  transformation: configuratorViewTransformation
                }"
                :nascar="configuratorNascarCuffs"
                :belt="configuratorBelt.show"
                :beltArea="configuratorBelt.beltArea"
                :pocket="configuratorPocket"
                :bigLoader="true"
                :hasMask="true"
                :hasDecals="true"
                :isFullWidth="$store.getters.isCurrentState('logo') || $store.getters.isCurrentState('text')"
                :handleClick="onAreaClick"
              />

              <BeltCanvas v-if="$store.getters.isCurrentState('text')" />
              <DecalsCanvas v-if="$store.getters.isCurrentState('logo')" />
              <DecalsMask
                v-if="$store.getters.isCurrentState('logo')"
                :transformation="configuratorViewTransformation"
              />
            </div>
            <div class="right-arrow" v-show="configuratorCurrentStep.viewsNavigation === true">
              <ChevronRight @click.native="nextViewAngle()" />
            </div>

            <DecalsOverview
              :currentView="configuratorCurrentViewAngle"
              v-if="$store.getters.isCurrentState('summary')"
            />
          </div>
          <div class="views-thumbnails-container" v-if="configuratorCurrentStep.viewsNavigation === true">
            <div class="row h-100">
              <div class="col-8 offset-2 col-xl-6 offset-xl-3 h-100">
                <div class="row h-100">
                  <div v-for="(view, index) in configuratorViews" class="h-100 col-3 col-xl-3" :key="'view-' + index">
                    <div
                      class="views-thumbnails h-100"
                      :class="{
                        'box-active': configuratorCurrentViewAngle === index,
                        'cursor-pointer': configuratorCurrentViewAngle !== index
                      }"
                      @click="changeViewAngle(index)"
                    >
                      <SVGViewer
                        :viewData="{
                          asset: view,
                          areas: currentConfigurableStyleAreas,
                          transformation: null
                        }"
                        :nascar="configuratorNascarCuffs"
                        :selected="configuratorCurrentViewAngle === index"
                        :belt="configuratorBelt.show"
                        :beltArea="configuratorBelt.beltArea"
                        :pocket="configuratorPocket"
                        :pdfLinkedElement="`.pdf-${index}-view`"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
          </div>
        </div>
        <!-- parte sinistra -->
        <div
          class="col-md-6 config-section h-100"
          :class="{ 'is-summary': $store.state.configurator.configState === 'summary' }"
        >
          <!-- selezione della tab di configurazione -->
          <div class="row title-config">
            <div class="col-md-12 d-flex flex-row" v-if="$store.state.configurator.configState !== 'summary'">
              <div
                class="menu text-center cursor-pointer"
                v-for="(elem, index) in configuratorSteps"
                :key="'step-' + index"
                :class="{ 'menu-active': $store.state.configurator.configState === elem.id }"
                @click="$store.commit('CONFIGURATOR_CHANGE_STATE', elem.id)"
              >
                {{ $t(elem.label) }}
              </div>
            </div>
            <div class="col-md-12 d-flex flex-row" v-if="$store.state.configurator.configState === 'summary'">
              <div class="menu is-summary menu-active text-center cursor-pointer">
                {{ $t('CFG_MENU_SUMMARY') }}
              </div>
              <div class="menu text-right close-summary">
                <span
                  class="button-close cursor-pointer"
                  @click="$store.commit('CONFIGURATOR_CHANGE_STATE', configuratorPreviousStep.id)"
                >
                  <Close
                /></span>
              </div>
            </div>
          </div>
          <!-- sezione relativa al tab di configurazione attivo -->
          <div class="scrollable-area" :class="{ 'is-summary': $store.state.configurator.configState === 'summary' }">
            <div class="row">
              <div class="col-md-12 body-config">
                <Design v-if="$store.getters.isCurrentState('design')" />
                <Style v-if="$store.getters.isCurrentState('style')" />
                <Color v-if="$store.getters.isCurrentState('color')" :areas="currentConfigurableStyleAreas" />
                <BeltConfig v-if="$store.getters.isCurrentState('text')" />
                <Size v-if="$store.getters.isCurrentState('size')" />
                <Logo v-if="$store.getters.isCurrentState('logo')" />
                <Summary v-if="$store.getters.isCurrentState('summary')" :goBackHandler="goBack" />
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-12 navigator"
              :class="{ 'is-summary': $store.state.configurator.configState === 'summary' }"
            >
              <div class="row">
                <div class="col-6 text-left">
                  <button
                    type="button"
                    :class="{ disabled: !configuratorStepsIsPrevAvailable }"
                    @click="$store.dispatch('CONFIGURATOR_GOTO_PREV_STATE')"
                  >
                    <span class="icon"><ChevronLeft /></span>
                    <span class="text">{{ $t('ACTION_PREV') }}</span>
                  </button>
                </div>
                <div class="col-6 text-right">
                  <button
                    type="button"
                    :class="{ disabled: !configuratorStepsIsNextAvailable }"
                    @click="$store.dispatch('CONFIGURATOR_GOTO_NEXT_STATE')"
                  >
                    <span class="text">{{ $t('ACTION_NEXT') }}</span>
                    <span class="icon"><ChevronRight /></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-12 footer flex-row-reverse d-flex align-items-center"
              :class="{ 'is-summary': $store.state.configurator.configState === 'summary' }"
            >
              <p
                class="footer-text cursor-pointer"
                v-if="$store.state.configurator.configState !== 'summary'"
                @click="$store.commit('CONFIGURATOR_CHANGE_STATE', 'summary')"
              >
                {{ $t('CFG_FOOT_SAVE_BTN') }}
                <ContentSave />
              </p>
              <p
                class="footer-text cursor-pointer"
                v-if="$store.state.configurator.configState === 'summary'"
                @click="$store.commit('CONFIGURATOR_CHANGE_STATE', configuratorPreviousStep.id)"
              >
                {{ $t('CFG_FOOT_BACK_CONFIG') }}
                <Palette />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- LOADING -->
    <div
      class="row start-left-side h-100"
      v-if="isOrdersFetching || isConfiguratorFetching || isLogosFetching || isB2cFetching || isSizeYouFetching"
    >
      <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
          <div class="col text-center">
            <img id="sparco-logo-loading" :src="logoCEasy" />
            <Loader />
          </div>
        </div>
      </div>
    </div>
    <!-- ERROR -->
    <div class="h-100" v-if="ordersError || configuratorError">
      <img class="ceasy-logo-error img-fluid" src="@/assets/logo_customeasy_oriz.png" />
      <div class="row h-100">
        <div class="col d-flex flex-wrap justify-content-center align-items-center">
          <ErrorMessage />
        </div>
      </div>
    </div>
    <!-- B2C THANKYOU -->
    <B2CThankYou v-if="!ordersError && !configuratorError && b2cThankYou" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContentSave from 'vue-material-design-icons/ContentSave.vue'
import ChevronRight from 'vue-material-design-icons/ChevronRight.vue'
import ChevronLeft from 'vue-material-design-icons/ChevronLeft.vue'
import Close from 'vue-material-design-icons/Close.vue'
import Palette from 'vue-material-design-icons/Palette.vue'
import Design from '@/components/configurator/Design.vue'
import Style from '@/components/configurator/Style.vue'
import Color from '@/components/configurator/Color.vue'
import BeltCanvas from '@/components/configurator/BeltCanvas.vue'
import BeltConfig from '@/components/configurator/BeltConfig.vue'
import Size from '@/components/configurator/Size.vue'
import Logo from '@/components/configurator/Logo.vue'
import Summary from '@/components/configurator/Summary.vue'
import DecalsCanvas from '@/components/configurator/DecalsCanvas.vue'
import DecalsMask from '@/components/configurator/DecalsMask.vue'
import SVGViewer from '@/components/configurator/SVGViewer.vue'
import Loader from '@/components/Loader.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import PDFView from '@/components/configurator/PDFView.vue'
import logoCEasy from '@/assets/logo_customeasy_oriz_white.png'
import DecalsOverview from '@/components/configurator/DecalsOverview.vue'
import LanguageSwitch from '@/components/LanguageSwitch.vue'
import B2CThankYou from '@/components/B2CThankYou.vue'

export default {
  name: 'configurator',
  components: {
    ContentSave,
    ChevronLeft,
    Close,
    Palette,
    ChevronRight,
    Design,
    Style,
    Color,
    BeltCanvas,
    BeltConfig,
    Size,
    Logo,
    Summary,
    DecalsCanvas,
    DecalsMask,
    Loader,
    ErrorMessage,
    SVGViewer,
    PDFView,
    DecalsOverview,
    LanguageSwitch,
    B2CThankYou
  },
  computed: {
    ...mapGetters([
      'loggedUser',
      'isOrdersFetching',
      'ordersError',
      'orderSelectedItem',
      'ordersLastQuery',
      'isConfiguratorFetching',
      'configuratorError',
      'configuratorModels',
      'configuratorActualModel',
      'configuratorCurrentView',
      'configuratorCurrentViewAngle',
      'configuratorViews',
      'configuratorViewTransformation',
      'currentStyle',
      'currentStyleAreas',
      'configuratorBelt',
      'configuratorPocket',
      'configuratorNascarCuffs',
      'configuratorSteps',
      'configuratorCurrentStep',
      'configuratorPreviousStep',
      'isLogosFetching',
      'isB2cFetching',
      'b2cDealer',
      'b2cThankYou',
      'configuratorStepsIsPrevAvailable',
      'configuratorStepsIsNextAvailable',
      'isSizeYouFetching'
    ]),
    currentConfigurableStyleAreas() {
      // if Nascar then don't show the style area for the ankles since it won't be shown nor can be configured
      return this.currentStyleAreas.filter(area => (this.configuratorNascarCuffs ? area.domID !== 'caviglie' : true))
    }
  },
  data() {
    return {
      logoCEasy
    }
  },
  methods: {
    onAreaClick(evt, selectedColorArea) {
      if (this.configuratorCurrentStep && this.configuratorCurrentStep.id !== 'color') {
        setTimeout(() => {
          this.$store.commit('CONFIGURATOR_CHANGE_STATE', 'color')
          this.$store.commit('CONFIGURATOR_SELECT_COLOR_AREA', selectedColorArea)
        }, 250)
      } else {
        this.$store.commit('CONFIGURATOR_SELECT_COLOR_AREA', selectedColorArea)
      }
    },
    goBack() {
      this.$router.push({ name: 'dashboard', query: this.ordersLastQuery })
    },
    changeViewAngle(angle) {
      if (this.configuratorCurrentViewAngle !== angle) {
        this.$store.commit('CONFIGURATOR_SET_VIEW_ANGLE', angle)
      }
    },
    nextViewAngle() {
      const viewsArray = Object.keys(this.configuratorViews)
      const currentAngleIndex = viewsArray.indexOf(this.configuratorCurrentViewAngle)
      if (currentAngleIndex !== -1) {
        const newAngleIndex = (currentAngleIndex + 1) % viewsArray.length
        const newAngle = viewsArray[newAngleIndex]
        this.changeViewAngle(newAngle)
      }
    },
    prevViewAngle() {
      const viewsArray = Object.keys(this.configuratorViews)
      const currentAngleIndex = viewsArray.indexOf(this.configuratorCurrentViewAngle)
      if (currentAngleIndex !== -1) {
        const newAngleIndex = (currentAngleIndex + viewsArray.length - 1) % viewsArray.length
        const newAngle = viewsArray[newAngleIndex]
        this.changeViewAngle(newAngle)
      }
    },
    askReload(e) {
      if (!this.$store.state.configurator.skipNavigationAlert) {
        e.preventDefault()
        e.returnValue = 'Exit'
      }
    }
  },
  created() {
    this.$store.commit('B2C_THANK_YOU', false)
    if (this.$route.meta && this.$route.meta.b2c === true) {
      this.$store.dispatch('B2C_GET_DEALER', this.$route.params.token).then(res => {
        if (res === false) {
          this.$store.commit('CONFIGURATOR_SKIP_NAVIGATION_ALERT', true)
          this.$router.push({ path: '/404' })
        } else {
          this.$store.commit('ORDERS_GET_ITEM_SUCCESS', {
            id: -1,
            discount: 0,
            notes: '',
            quantity: 1,
            status: 'NEW',
            consumerName: '',
            consumerEmail: '',
            consumerPhone: ''
          })
          this.$store.dispatch('CONFIGURATOR_GET_CATALOG', this.b2cDealer.company).then(() => {
            this.$store.dispatch('CONFIGURATOR_SELECT_MODEL', this.configuratorModels[0].sku).then(() => {
              this.$store.commit('CONFIGURATOR_CHANGE_SIZETYPE', { type: 'standard' })
            })
          })
        }
      })
    } else {
      this.$store.dispatch('ORDERS_GET_ITEM', this.$route.params.order).then(() => {
        if (this.orderSelectedItem && Object.keys(this.orderSelectedItem).length > 0) {
          this.$store.dispatch('CONFIGURATOR_GET_CATALOG', this.orderSelectedItem.userCompany).then(() => {
            // Ordine nuovo o senza configurazione salvata
            if (this.orderSelectedItem.status === 'NEW' || this.orderSelectedItem.configuration === null) {
              this.$store.dispatch('CONFIGURATOR_SELECT_MODEL', this.configuratorModels[0].sku).then(() => {
                this.$store.commit('CONFIGURATOR_CHANGE_SIZETYPE', { type: 'standard' })
              })
            } else {
              // Ordine non nuovo e con una configurazione salvata
              this.$store
                .dispatch('CONFIGURATOR_SELECT_MODEL', this.orderSelectedItem.configuration.sku)
                .then(() => {
                  this.$store.dispatch('CONFIGURATOR_IMPORT', {
                    configuration: this.orderSelectedItem.configuration
                  })
                  this.$store.commit('CONFIGURATOR_CHANGE_STATE', 'color')
                })
                .catch(() => {
                  this.$store.commit('CONFIGURATOR_IS_FETCHING', false)
                  this.$store.commit('CONFIGURATOR_SKIP_NAVIGATION_ALERT', true)
                  this.$store.commit('CONFIGURATOR_ERROR')
                })
            }
          })
        }
      })
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.askReload)
    this.$store.commit('CONFIGURATOR_SKIP_NAVIGATION_ALERT', false)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.askReload)
    this.$store.dispatch('CONFIGURATOR_INITAL_VALUES')
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.configurator.skipNavigationAlert === true) {
      next()
    } else {
      const answer = window.confirm(this.$t('EXIT_SURE_QUESTION'))
      if (answer) {
        if (this.orderSelectedItem && this.orderSelectedItem.status === 'NEW' && !this.$route.meta.b2c) {
          this.$store.dispatch('ORDERS_DELETE_FORCE', this.orderSelectedItem.id).then(() => next())
        } else {
          next()
        }
      } else {
        next(false)
      }
    }
  }
}
</script>

<style lang="scss">
.configurator {
  background-color: $c-white;
  overflow: hidden;

  .B2C_thank-you {
    color: $c-blue;
    font-size: 30px;
    .check-icon {
      font-size: 70px;
      color: $c-yellow;
    }
    p {
      font-size: 20px;
    }
  }

  .ceasy-logo-error {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 50%;
    max-width: 350px;
  }

  .start-loader {
    .Loader {
      background: transparent;
    }
  }
  .start-btn {
    font-size: 25px;
    width: 100%;
    padding: 15px;
    height: auto !important;
    color: $c-blue;
    text-align: center;
    text-decoration: none;
    &:active,
    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
      color: $c-blue;
    }
  }
  .Loader {
    background: rgba($c-white, 0.4);
  }

  .header {
    position: absolute;
    z-index: 9995;
    top: 0;
    left: 0;
    width: 100%;
    height: 12%;

    @include only-xl() {
      height: 15%;
    }

    #exit {
      position: absolute;
      top: 10%;
      border-right: 10px solid $c-blue;
      // padding: 25% 25%;
      padding-top: 5px;
      height: 86%;
      width: 90%;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: $c-blue;

      @include only-xl() {
        padding-top: 30px;
      }

      span {
        font-size: 35px;
      }
    }

    #logo-container {
      margin-top: 15px;
      padding-bottom: 2%;

      img {
        margin-bottom: 5px;
        max-width: 180px;

        @include only-xl() {
          margin-bottom: 10px;
          max-width: 250px;
        }
      }

      &.noExit {
        margin-top: 30px;
        margin-left: 30px;
      }
    }

    #model-name {
      color: $c-blue;
      border-top: 2px solid $c-blue;
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      display: inline-block;

      @include only-xl() {
        font-size: 20px;
      }

      &.noExit {
        margin-left: 30px;
      }
    }
  }

  .main-view-area {
    position: absolute;
    top: 15%;
    left: 0;
    width: 100%;
    height: 72%;

    &.full-height {
      width: 100%;
      height: 83%;

      .main-view {
        width: 90%;
        left: 5%;
        height: 90%;
        &.in-belt-step {
          width: 500px;
          height: 500px;
          display: inline-block;
        }
      }
    }
    .main-view {
      position: absolute;
      height: 100%;
      width: 70%;
      left: 15%;
    }
    .left-arrow {
      position: absolute;
      height: 100%;
      width: 15%;
      left: 0%;
      span {
        font-size: 70px;
        height: 20%;
        top: 35%;
        left: 15%;
        color: $c-blue;
        cursor: pointer;

        @include only-xl() {
          font-size: 100px;
        }
      }
    }
    .right-arrow {
      position: absolute;
      height: 100%;
      width: 15%;
      right: 0%;
      span {
        font-size: 70px;
        height: 20%;
        right: 15%;
        top: 35%;
        color: $c-blue;
        cursor: pointer;

        @include only-xl() {
          font-size: 100px;
        }
      }
    }
  }
  .views-thumbnails-container {
    position: absolute;
    bottom: 0%;
    left: 0;
    width: 100%;
    height: 13%;
    padding: 2%;
    .views-thumbnails {
      user-select: none;
      border: 2px solid $c-blue;
      .SVGViewer object[style] {
        height: 80% !important;
      }
    }
  }

  .config-section {
    background: linear-gradient(45deg, $c-blue 0%, $c-lightblue 100%);
    &.is-summary {
      background: $c-grey2;
    }
  }

  .scrollable-area {
    &.is-summary {
      bottom: 65px;
    }
    position: absolute;
    left: 15px;
    right: 15px;
    top: 59px;
    bottom: 115px;
    overflow-x: hidden;
    overflow-y: scroll;

    @include only-xl() {
      top: 91px;
      bottom: 125px;
      &.is-summary {
        bottom: 75px;
      }
    }
  }

  .navigator {
    &.is-summary {
      display: none;
    }
    position: absolute;
    height: 50px;
    bottom: 0;
    width: 100%;
    bottom: 50px;
    @include only-xl() {
      bottom: 60px;
    }

    button {
      cursor: pointer;
      color: $c-yellow;
      font-weight: bold;
      background: none;
      border: none;
      padding: 0;
      margin: 0;

      &.disabled {
        display: none;
      }

      .icon {
        display: inline-block;
        font-size: 40px;
        line-height: 40px;
        vertical-align: middle;
      }
      .text {
        text-transform: uppercase;
        vertical-align: middle;
        display: inline-block;
        padding-top: 5px;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $c-grey2;
    height: 50px;
    display: flex;

    &.is-summary {
      background: linear-gradient(45deg, $c-blue 0%, $c-lightblue 100%);
      .footer-text {
        color: $c-white;
        font-weight: normal;
      }
    }

    @include only-xl() {
      height: 60px;
    }

    .footer-text {
      font-size: 16px;
      margin-bottom: 0;
      text-align: right;
      text-transform: uppercase;
      color: $c-blue;
      font-weight: bold;
      @include only-xl() {
        font-size: 20px;
      }
    }
  }

  .menu {
    width: 20%;
    display: inline-block;
    vertical-align: middle;
    padding: 15px 0;
    text-transform: uppercase;
    @include only-xl() {
      padding: 25px 0;
    }

    &.close-summary {
      width: 80%;
      color: $c-blue;
      .button-close {
        border: 2px solid $c-blue;
        padding: 5px 8px;
      }
    }
  }

  .title-config {
    font-size: 16px;
    border-bottom: 5px solid $c-white;
    color: $c-white;
    font-weight: regular;

    @include only-xl() {
      font-size: 21px;
      border-bottom: 10px solid $c-white;
    }
  }

  .menu-active {
    font-weight: bold;
    color: $c-yellow;
    background-image: url(../assets/active_arrow.png);
    background-position: bottom;
    background-size: 50%;
    background-repeat: no-repeat;

    &.is-summary {
      color: $c-blue;
    }
  }

  .body-config {
    color: $c-white;
    margin-top: 15px;
    padding-right: 30px;
    padding-left: 30px;
  }

  h2 {
    border-bottom: 2px solid $c-white;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 5px;
    margin: 10px 0px 20px 0px;

    @include only-xl() {
      font-size: 18px;
      margin: 10px 0px 15px 0px;
    }
  }

  .title-config .col-md {
    cursor: pointer;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to .fade-leave-active {
    opacity: 0;
  }

  .btn-outline {
    background-color: rgba($c-white, 0);
    border-radius: 0;
    border: 2px solid $c-white !important;
    margin-bottom: 15px;
    color: $c-white !important;
    height: 40px !important;
    font-size: 18px;
    width: 100%;
  }

  .btn-outline-selected {
    background-color: $c-yellow !important;
    border: 2px solid $c-yellow !important;
    color: $c-blue !important;
  }
}
</style>
