<template>
  <div id="decals">
    <div class="row">
      <div class="col-md-6">
        <h2>{{ $t('CFG_LOGO_TITLE_SELECT') }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6" v-for="(elem, index) in $store.getters.getDecalAreaList" :key="index">
        <button
          class="btn-outline"
          :class="{
            'cursor-pointer': $store.state.configurator.actualDecalArea.name !== elem.name,
            'cursor-default btn-outline-selected': $store.state.configurator.actualDecalArea.name === elem.name
          }"
          @click="selectDecalArea(index, elem)"
        >
          {{ $t(elem.name) }}
        </button>
      </div>
    </div>
    <div class="row" v-if="$store.getters.logoModeAvailable.length > 0">
      <div class="col-md-6">
        <h2>{{ $t('CFG_LOGO_TITLE_MODE') }}</h2>
      </div>
    </div>
    <div class="row" v-if="$store.getters.logoModeAvailable.length > 0">
      <div class="col-6">
        <div class="row">
          <div class="col-12 col-xl-6" v-if="$store.getters.logoModeAvailable.includes('print')">
            <button
              class="btn-outline"
              @click="setLogoMode(0)"
              :class="{
                'cursor-default btn-outline-selected': $store.state.configurator.currentLogoMode === 0,
                'cursor-pointer': $store.state.configurator.currentLogoMode !== 0
              }"
            >
              {{ $t('CFG_LOGO_TITLE_MODE_PRINT') }}
            </button>
          </div>
          <div class="col-12 col-xl-6" v-if="$store.getters.logoModeAvailable.includes('embroidery')">
            <button
              class="btn-outline"
              @click="setLogoMode(1)"
              :class="{
                'cursor-default btn-outline-selected': $store.state.configurator.currentLogoMode === 1,
                'cursor-pointer': $store.state.configurator.currentLogoMode !== 1
              }"
            >
              {{ $t('CFG_LOGO_TITLE_MODE_EMBROIDERY') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h2>{{ $t('CFG_LOGO_TITLE_UPLOAD') }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="uploader library">
          <div class="image-upload" @click="error = null" v-b-modal="'modalLogoLibrary'">
            <div class="row">
              <div class="col-12 text-center">
                <span class="upload-icon">
                  <BookOpenPageVariant />
                </span>
                <span class="upload-text">
                  {{ $t('CFG_LOGO_LIBRARY') }}
                </span>
              </div>
            </div>
          </div>
          <b-modal
            id="modalLogoLibrary"
            :ref="'modalLogoLibrary'"
            :title="$t('PARAMETERS_NAV_LOGOS')"
            :size="$store.getters.logosItems.length > 1 ? 'lg' : 'md'"
            centered
            hide-footer
            @shown="onModalLibraryShown()"
            @hide="onModalLibraryHide()"
          >
            <div class="row" v-if="$store.getters.logosItems.length > 1">
              <div class="col-12">
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <div class="float-left mr-2">
                        <Magnify class="logo-lib-search-icon" />
                      </div>
                      <div class="float-left">
                        <input type="text" placeholder="Search Logo" class="logo-lib-search" v-model="libraryFilter" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="$store.getters.logosItems.length > 0" class="logo-list">
              <div class="row">
                <div
                  class="mb-4"
                  v-for="logoLibItem in logosItemsVisibleInLibrary"
                  :key="logoLibItem.id"
                  :class="{
                    'col-12': $store.getters.logosItems.length === 1,
                    'col-6': $store.getters.logosItems.length > 1
                  }"
                >
                  <h4 class="logo-lib-title">{{ logoLibItem.label }}</h4>
                  <div class="text-center">
                    <img
                      :src="logoLibItem.path"
                      @click="selectLogoFromLibrary(logoLibItem)"
                      style="max-height: 100px;"
                      class="img-fluid cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center no-logos" v-if="logosItemsVisibleInLibrary.length === 0">
                <div class="icon">
                  <Alert />
                </div>
                <p>{{ $t('CFG_LOGO_LIBRARY_NO_ELEM_BY_FILTER') }}</p>
              </div>
            </div>
            <div v-if="!$store.getters.isLogosFetching && $store.getters.logosItems.length === 0">
              <div class="text-center no-logos">
                <div class="icon">
                  <Alert />
                </div>
                <p>{{ $t('CFG_LOGO_LIBRARY_NO_ELEMS') }}</p>
              </div>
            </div>
          </b-modal>
        </div>

        <div class="uploader">
          <div class="image-upload" @click="error = null">
            <div class="row">
              <div class="col text-center">
                <span class="upload-icon"> <CloudUpload /></span
                ><span class="upload-text" v-html="$t('CFG_LOGO_UPLOAD_HINT')"></span>
              </div>
            </div>
          </div>
          <input
            id="logo-update-btn"
            type="file"
            @change="uploadImage($event)"
            :disabled="!$store.state.configurator.decalsStatus.updated"
          />
        </div>
        <!-- errore -->
        <p class="text-center text-danger" v-if="error">{{ $t(error) }}</p>
        <div class="img-rows-container">
          <div
            class="row no-gutters row-eq-height img_element mb-2"
            v-for="(elem, index) in $store.state.configurator.actualDecalArea.logos"
            :key="index"
          >
            <div class="col-4 col-xl-2">
              <div class="img-container mr-2"><img :src="elem.src" /></div>
            </div>
            <div class="d-none d-xl-block col-xl-6">
              <p class="d-none d-xl-block img-name ml-2 text-center">{{ elem.cm2 | logoSurface }}<sup>2</sup></p>
            </div>
            <div class="col-4 col-xl-2">
              <div class="img-actions-container text-center ml-2 cursor-pointer">
                <button class="cursor-pointer" @click="deleteImage(elem.id)">
                  <TrashCanOutline />
                </button>
              </div>
            </div>
            <div class="col-4 col-xl-2">
              <div class="img-actions-container text-center ml-2 cursor-pointer">
                <button class="cursor-pointer" @click="copyImage(elem)">
                  <ContentCopy />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- istruzioni-->
      <div class="col-md-6">
        <div class="uploader">
          <div class="image-upload white" v-b-modal="'modalLogoWarn'">
            <div class="row">
              <div class="col text-center">
                <span class="upload-icon"> <Alert /></span
                ><span class="upload-text" v-html="$t('CFG_LOGO_WARNING_HINT')"></span>
              </div>
            </div>
            <b-modal
              id="modalLogoWarn"
              :ref="'modalLogoWarn'"
              :title="$t('CFG_LOGO_WARNING_TITLE')"
              centered
              no-close-on-backdrop
              hide-footer
            >
              <span
                v-html="`${isUnderwear ? $t('CFG_LOGO_INSTRUCTION_UNDERWEAR') : $t('CFG_LOGO_INSTRUCTION')}`"
              ></span>
            </b-modal>
          </div>
        </div>
        <div class="logos-summary">
          <div class="row">
            <div class="col-6">{{ $t('CFG_LOGO_NUM_LOGOS') }}</div>
            <div class="col-6 text-right">{{ $store.getters.getNumLogo }}</div>
          </div>
          <div class="row mt-3" v-if="$store.getters.userCompany === 'ITA'">
            <div class="col-6">{{ $t('CFG_LOGO_CREDIT') }}</div>
            <div class="col-6 text-right">{{ $store.getters.getDecalCredits }}</div>
          </div>
          <div class="row mt-3" v-if="$store.getters.userCompany === 'USA'">
            <div class="col-6">{{ $t('PRICE') }}</div>
            <div class="col-6 text-right">
              {{
                ($store.getters.logosPrice().logoSurface + $store.getters.logosPrice().digitalization).toFixed(2)
                  | price
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Alert from 'vue-material-design-icons/Alert.vue'
import ContentCopy from 'vue-material-design-icons/ContentCopy.vue'
import CloudUpload from 'vue-material-design-icons/CloudUpload.vue'
import TrashCanOutline from 'vue-material-design-icons/TrashCanOutline.vue'
import BookOpenPageVariant from 'vue-material-design-icons/BookOpenPageVariant.vue'
import Magnify from 'vue-material-design-icons/Magnify.vue'

var _self
export default {
  components: {
    Alert,
    CloudUpload,
    ContentCopy,
    BookOpenPageVariant,
    TrashCanOutline,
    Magnify
  },
  data() {
    return {
      max_file_size: 2500, // size in kb
      acceptedMimes: ['image/gif', 'image/jpeg', 'image/png'],
      error: null,
      libraryFilter: ''
    }
  },
  computed: {
    logosItemsVisibleInLibrary() {
      return this.$store.getters.logosItems.filter(elem => {
        return elem.label.toLowerCase().includes(this.libraryFilter.toLowerCase())
      })
    },
    isUnderwear() {
      return this.$store.state.configurator.actualModel.type === 'underwear'
    }
  },
  created() {
    _self = this
    this.$store.commit('CONFIGURATOR_SELECT_DECAL_AREA', 0)
  },
  mounted() {
    this.$store.commit('CONFIGURATOR_SET_VIEW_ANGLE', this.$store.getters.getDecalAreaList[0].view)
    this.$store.commit('CONFIGURATOR_SET_VIEW_TRANSFORMATION', this.$store.getters.getDecalAreaList[0].transform)
  },
  methods: {
    //metodo di upload delle immagini
    uploadImage: function(event) {
      _self.error = null
      var input = event.target
      if (input.files && input.files[0] && input.files.length > 0) {
        //controllo il tipo di file che mi arriva
        if (!_self.acceptedMimes.includes(input.files[0].type)) {
          _self.error = 'CFG_ERROR_FILENOTSUPPORTED'
          return
        }
        //se pesa meno del limite max consentito
        if (input.files[0].size / 1024 < _self.max_file_size) {
          var FR = new FileReader()
          FR.addEventListener('load', function(e) {
            if (e !== undefined) {
              //mando il messaggio di caricamento
              _self.checkOrderCreated(() => {
                _self.$store
                  .dispatch('ORDERS_UPLOAD_DECAL', {
                    orderId: _self.$store.getters.orderSelectedItem.id,
                    decal: { type: input.files[0].type, data: e.target.result }
                  })
                  .then(resp => {
                    //resp:  { name, src }
                    _self.$store.commit('CONFIGURATOR_DECAL_UPDATING', {
                      type: 'add',
                      payload: resp
                    })
                    //resetto il tasto upload
                    document.getElementById('logo-update-btn').value = ''
                  })
              })
            }
          })
          FR.readAsDataURL(input.files[0])
        } else {
          _self.error = 'CFG_ERROR_FILESIZE'
          return
        }
      } else {
        _self.error = null
      }
    },
    deleteImage(id) {
      this.$store.commit('CONFIGURATOR_DECAL_UPDATING', {
        type: 'remove',
        payload: id
      })
    },
    copyImage(elem) {
      this.$store.commit('CONFIGURATOR_DECAL_UPDATING', {
        type: 'add',
        payload: { name: elem.name, src: elem.src }
      })
    },
    selectDecalArea(index, element) {
      this.$store.commit('CONFIGURATOR_DECAL_UPDATING', { type: 'update', payload: index })
      this.$store.commit('CONFIGURATOR_SET_VIEW_ANGLE', element.view)
      this.$store.commit('CONFIGURATOR_SET_VIEW_TRANSFORMATION', element.transform)
    },
    setLogoMode(value) {
      this.$store.dispatch('CONFIGURATOR_SET_LOGO_MODE', value)
    },
    onModalLibraryShown() {
      const logosLibItems = this.$store.getters.logosItems
      if (logosLibItems.length === 0) {
        this.$store.dispatch('LOGOS_GET_LIST')
      }
    },
    onModalLibraryHide() {
      this.libraryFilter = ''
    },
    checkOrderCreated(callback) {
      // Check order if is already created in DB
      if (this.$store.getters.orderSelectedItem.id && this.$store.getters.orderSelectedItem.id > 0) {
        callback()
      } else if (this.$route.meta.b2c === true) {
        this.$store.dispatch('B2C_ORDER_CREATE', this.$route.params.token).then(() => {
          callback()
        })
      } else {
        this.$store.commit('ORDERS_ERROR')
      }
    },
    selectLogoFromLibrary(logo) {
      this.checkOrderCreated(() => {
        this.$store
          .dispatch('ORDERS_COPY_DECAL_FROM_LIBRARY', {
            orderId: this.$store.getters.orderSelectedItem.id,
            decal: logo.path.split('/').pop()
          })
          .then(resp => {
            this.$store.commit('CONFIGURATOR_DECAL_UPDATING', {
              type: 'add',
              payload: resp
            })
            this.$refs.modalLogoLibrary.hide()
          })
      })
    }
  },
  beforeDestroy() {
    this.$store.commit('CONFIGURATOR_SET_VIEW_ANGLE', 'front')
    this.$store.commit('CONFIGURATOR_SET_VIEW_TRANSFORMATION', {})

    const mainView = document.querySelector('.main-view')
    if (mainView) {
      mainView.removeAttribute('style')
    }
  }
}
</script>

<style lang="scss">
#modalLogoWarn,
#modalLogoLibrary {
  color: $c-blue;
}
#modalLogoLibrary {
  overflow: hidden;
  .logo-list {
    height: 400px;
    overflow: hidden;
    overflow-y: scroll;
  }
  .logo-lib-title {
    font-size: 18px;
    padding-bottom: 5px;
    border-bottom: 1px solid $c-grey;
  }
  .logo-lib-search-icon {
    font-size: 25px;
    padding-top: 5px;
  }
  .logo-lib-search {
    width: 335px;
    color: $c-blue;
    padding: 0 5px;
    border: 1px solid $c-grey !important;
  }
}

.image-upload {
  cursor: pointer;
  color: $c-blue;
  background-color: $c-grey2;
  border: 2px solid $c-grey2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  .upload-icon,
  .upload-text {
    display: inline-block;
    vertical-align: middle;
  }
  .upload-icon {
    font-size: 40px;
  }
  .upload-text {
    font-size: 16px;
    line-height: 18px;
    margin-left: 30px;
  }

  &.white {
    color: $c-white;
    background: transparent;
    border: 2px solid $c-white;
  }
}

#alert-info {
  color: $c-white;
  background-color: transparent;
  border: 2px solid $c-white;
}

.uploader {
  position: relative;
  height: 65px;
  margin-bottom: 15px;

  input[type='file'] {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 66%;
    opacity: 0;

    @include only-xl() {
      height: 100%;
    }
  }
}

.img-name {
  margin: 0;
  height: 100%;
  border: 2px solid $c-white;
  padding: 10px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.img-container {
  width: 100%;
  height: 100%;
  border: 2px solid $c-white;
  border-collapse: collapse;
  padding: 5px;
  img {
    width: 60%;
    margin-left: 20%;
  }
}

.img-actions-container {
  border: 2px solid $c-white;
  border-collapse: collapse;
  padding: 5px;
  height: 100%;
  button {
    border: none;
    background: transparent;
    color: $c-white;
    font-size: 24px;
  }
}
.alert {
  font-size: 45px;

  @include only-xl() {
    font-size: 60px;
  }
}

.logos-summary {
  font-size: 16px;
}

.logo-instruction {
  font-size: 12px;
  max-height: 70px;
  overflow-y: scroll;
  @include only-xl() {
    font-size: 18px;
    max-height: 5000px;
    overflow-y: hidden;
  }
}

hr {
  background: $c-white;
  height: 2px;
}

.no-logos {
  .icon {
    font-size: 40px;
  }
  color: $c-text;
}
</style>
