<template>
  <div class="SVGViewerContainer" :style="{ background: selected ? '#ffcf00' : '#fff' }">
    <div class="SVGViewer" :class="{ 'no-click': !handleClick }" ref="view"></div>
    <div class="loader" v-show="loading">
      <svg
        class="spinner"
        :class="{ 'big-loader': bigLoader === true }"
        width="66px"
        height="66px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SVGViewer',
  data() {
    return {
      loading: false,
      timer: null
    }
  },
  props: {
    viewData: {
      type: Object,
      required: true
    },
    bigLoader: {
      type: Boolean
    },
    handleClick: {
      type: Function
    },
    belt: {
      type: Boolean,
      required: true
    },
    beltArea: {
      type: String,
      required: true
    },
    pocket: {
      type: Boolean,
      required: true
    },
    nascar: {
      type: Boolean,
      required: true
    },
    pdfLinkedElement: {
      type: String
    },
    selected: {
      type: Boolean
    },
    hasMask: {
      type: Boolean
    },
    hasDecals: {
      type: Boolean
    },
    isFullWidth: {
      type: Boolean
    }
  },
  methods: {
    getColorByMaterial(material) {
      return this.$store.getters.getColorByMaterial(material)
    },
    getMaterialByCode(materialCode) {
      return this.$store.getters.getMaterialByCode(materialCode)
    },
    setTransform() {
      const containers = this.$refs.view.querySelectorAll('object')
      const container = containers[containers.length - 1]
      if (container) {
        const doc = container.getSVGDocument()
        const element = doc && doc.querySelector('svg')
        if (element) {
          element.style.transform = ''
          if (this.viewData.transformation && this.viewData.transformation.scale) {
            element.style.transform += `scale(${this.viewData.transformation.scale})`
          } else {
            element.style.transform += 'scale(1)'
          }
          if (this.viewData.transformation && this.viewData.transformation.position) {
            const translateX = this.viewData.transformation.position.x
            const translateY = this.viewData.transformation.position.y
            element.style.transform += `translate(${translateX}vh, ${translateY}vh)`
          } else {
            element.style.transform += 'translate(0,0)'
          }
        }
      }
    },
    fill() {
      const backgroundAreaSelector = '#SPAZIO_x5F_LAVORO'
      const elements = this.$refs.view.querySelectorAll('object')
      const element = elements[elements.length - 1]
      if (element) {
        const doc = element.getSVGDocument()
        this.setTransform()

        const background = doc ? doc.querySelector(backgroundAreaSelector) : null
        if (background) {
          background.childNodes.forEach(b => {
            const color = this.selected === true ? '#ffcf00' : '#fff'
            if (b.style) {
              b.style.fill = color
            }
          })
        }
        //Area colors
        this.viewData.areas.forEach(e => {
          const area = doc && e.domID ? doc.querySelector('#' + e.domID) : null
          if (area) {
            area.style.cursor = 'pointer'
            area.childNodes.forEach(c => {
              if (c.style) {
                c.style.fill = this.getColorByMaterial(e.selectedMaterial)
                c.style.strokeWidth = 0.25
                c.style.stroke = '#000'
              }
            })
            // Nascar
            if (e.domID === 'polsicaviglie' || e.domID === 'caviglie') {
              const nascarArea = doc.querySelector('#polsicaviglie_nascar') || doc.querySelector('#caviglie_nascar')
              if (nascarArea) {
                nascarArea.style.cursor = 'pointer'
                nascarArea.childNodes.forEach(c => {
                  if (c.style) {
                    c.style.fill = this.getColorByMaterial(e.selectedMaterial)
                  }
                })
              }
            }
            // Loghi
            const areaLoghi = doc.querySelector('#' + e.domID + '_loghi')
            const material = this.getMaterialByCode(e.selectedMaterial)
            if (areaLoghi && material) {
              areaLoghi.childNodes.forEach(alc => {
                if (alc.style) {
                  alc.style.fill = this.getColorByMaterial(material.contrastColor)
                }
              })
            }
            // Kit stampe
            const areaKit1 = doc.querySelector('#' + e.domID + '_kit1')
            const areaKit2 = doc.querySelector('#' + e.domID + '_kit2')
            if (areaKit1 && material) {
              areaKit1.childNodes.forEach(alc => {
                if (alc.style) {
                  alc.style.fill = this.getColorByMaterial(material.contrastColorKit.kit1)
                }
              })
            }
            if (areaKit2 && material) {
              areaKit2.childNodes.forEach(alc => {
                if (alc.style) {
                  alc.style.fill = this.getColorByMaterial(material.contrastColorKit.kit2)
                }
              })
            }
          }
        })
        //Belt color
        const beltAreaMaterial = this.viewData.areas.find(x => x.name === this.beltArea)
        if (beltAreaMaterial && beltAreaMaterial.selectedMaterial && beltAreaMaterial.selectedMaterial) {
          const beltAreaElement = doc && doc.querySelector('#cintura')
          const beltMaterial = this.getMaterialByCode(beltAreaMaterial.selectedMaterial)
          if (beltAreaElement && beltMaterial) {
            beltAreaElement.querySelectorAll(':not(g)').forEach(x => {
              x.style.fill = beltMaterial.color
            })
            const beltAreaLoghi = doc && doc.querySelector('#cintura_loghi')
            if (beltAreaLoghi) {
              beltAreaLoghi.querySelectorAll(':not(g)').forEach(x => {
                x.style.fill = this.getColorByMaterial(beltMaterial.contrastColor)
              })
            }
          }
        }
        //Pockets visibility
        const pocketSelector = doc && doc.querySelector('#tasche')
        if (pocketSelector) {
          pocketSelector.style.display = this.pocket === true ? 'block' : 'none'
        }
        //Nascar visibility
        const cuffsSelector = doc && (doc.querySelector('#polsicaviglie') || doc.querySelector('#caviglie'))
        const nascarSelector =
          doc && (doc.querySelector('#polsicaviglie_nascar') || doc.querySelector('#caviglie_nascar'))
        const anklesShadeSelector = doc && doc.querySelector('#caviglie_sfumature')

        if (cuffsSelector) {
          cuffsSelector.style.display = this.nascar === true ? 'none' : 'block'
        }
        if (nascarSelector) {
          nascarSelector.style.display = this.nascar === true ? 'block' : 'none'
        }
        if (anklesShadeSelector) {
          if (this.nascar) {
            anklesShadeSelector.style.display = 'none'
          } else {
            anklesShadeSelector.style.display = 'block'
            anklesShadeSelector.style.pointerEvents = 'none'
          }
        }

        //Belt visibility
        if (doc) {
          Array.apply(null, doc.querySelectorAll('g'))
            .filter(e => e.id && e.id.startsWith('cintura'))
            .forEach(x => (x.style.display = this.belt === true ? 'block' : 'none'))
        }
        //Print image in base64 inside the pdf element (if specified)
        if (doc && this.pdfLinkedElement) {
          const pageContainer = document.querySelector('#pagina1')
          if (pageContainer) {
            const pdfContainer = pageContainer.querySelector(this.pdfLinkedElement)
            if (pdfContainer) {
              const svg = doc.querySelector('svg') ? doc.querySelector('svg').cloneNode(true) : null
              if (svg) {
                if (svg.querySelector(backgroundAreaSelector)) {
                  svg.removeChild(svg.querySelector(backgroundAreaSelector))
                }
                if (svg.querySelector('#sfumature')) {
                  svg.removeChild(svg.querySelector('#sfumature'))
                }
                if (svg.querySelector('#cintura_sfumature')) {
                  svg.removeChild(svg.querySelector('#cintura_sfumature'))
                }
                if (svg.querySelector('#caviglie_sfumature')) {
                  svg.removeChild(svg.querySelector('#caviglie_sfumature'))
                }
                const svgHtml = svg.outerHTML
                pdfContainer.innerHTML = `<img src="data:image/svg+xml;base64,${window.btoa(svgHtml)}" />`
              }
            }
          }
        }
      }
    },
    manageMaskVisibility(value) {
      const mask = document.querySelector('.decals-mask')
      if (this.hasMask && mask) {
        mask.style.opacity = value
      }
    },
    manageDecalsVisibility(value) {
      const layer = document.querySelector('.decals_overview')
      if (this.hasDecals && layer) {
        if (value === 0) {
          layer.style.transition = 'opacity 0s'
        } else {
          layer.style.transition = 'opacity 0.5s'
        }
        layer.style.opacity = value
      }
    },
    loadAsset() {
      this.loading = true

      const oldElement = this.$refs.view && this.$refs.view.childNodes ? this.$refs.view.childNodes[0] : null
      const element = document.createElement('object')
      element.addEventListener('load', () => {
        const doc = element.getSVGDocument()
        const sfumature = doc.querySelector('#sfumature')
        if (sfumature) {
          sfumature.style.pointerEvents = 'none'
        }
        if (doc) {
          Array.apply(null, doc.querySelectorAll('g')).forEach(e => {
            e.addEventListener('click', evt => {
              evt.stopPropagation()
              evt.preventDefault()
              const selectedColorArea = this.viewData.areas.reduce((res, x, i) => {
                // Remove _nascar suffix in order to catch all polsicaviglie areas!
                const searchId = e.id && typeof e.id === 'string' ? e.id.replace('_nascar', '') : ''
                if (x.domID === searchId) res = { ...x, index: i }
                return res
              }, null)

              if (selectedColorArea !== null) {
                const currentColor = this.getColorByMaterial(selectedColorArea.selectedMaterial)
                e.childNodes.forEach(c => {
                  if (c.style) {
                    c.style.fill = '#fbe168'
                    setTimeout(() => {
                      c.style.fill = currentColor
                    }, 250)
                  }
                })
                this.handleClick(evt, selectedColorArea.index)
              }
            })
          })
        }
        this.fill()
        element.style.opacity = 1
      })
      element.addEventListener('transitionend', () => {
        try {
          if (oldElement) {
            this.$refs.view.removeChild(oldElement)
          }
        } catch (e) {
          this.$refs.view.childNodes.forEach(x => {
            if (!x.isEqualNode(element)) {
              x.parentNode.removeChild(x)
            }
          })
        }
        this.manageMaskVisibility(1)
        this.manageDecalsVisibility(1)
        window.dispatchEvent(new Event('resize'))
        this.loading = false
      })

      element.style.transition = 'opacity 1s'
      element.style.height = '100%'
      element.style.width = '100%'
      element.style.opacity = 0
      element.data = this.viewData.asset

      this.$refs.view.appendChild(element)
    }
  },
  mounted() {
    this.loadAsset()
  },
  watch: {
    viewData: {
      deep: true,
      handler(newValue, oldValue) {
        const newAsset = newValue.asset
        const oldAsset = oldValue ? oldValue.asset : null

        this.manageMaskVisibility(0)
        this.manageDecalsVisibility(0)

        if (newAsset !== oldAsset) {
          clearTimeout(this.timer)
          this.$refs.view.innerHTML = ''
          this.timer = setTimeout(() => {
            this.loadAsset()
          }, 500)
        } else {
          this.fill()
          setTimeout(() => {
            this.manageMaskVisibility(1)
            this.manageDecalsVisibility(1)
          }, 250)
        }
      }
    },
    pocket() {
      this.fill()
    },
    nascar() {
      this.fill()
    },
    belt() {
      this.fill()
    }
  }
}
</script>

<style scoped lang="scss">
$offset: 187;
$duration: 1.4s;

.SVGViewerContainer {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SVGViewer {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &.no-click {
    pointer-events: none;
  }
}
.loader {
  z-index: 8999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .spinner {
    animation: rotator $duration linear infinite;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    margin-top: -15px;
    left: 50%;
    margin-left: -15px;

    &.big-loader {
      width: 60px;
      height: 60px;
      margin-top: -30px;
      margin-left: -30px;
    }
  }

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  .path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite, colors ($duration * 4) ease-in-out infinite;
  }

  @keyframes colors {
    0% {
      stroke: $c-blue;
    }
    33% {
      stroke: $c-white;
    }
    66% {
      stroke: $c-blue;
    }
    100% {
      stroke: $c-white;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: $offset;
    }
    50% {
      // stroke-dashoffset: $offset/4;
      stroke-dashoffset: calc($offset / 4);
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: $offset;
      transform: rotate(450deg);
    }
  }
}
</style>
