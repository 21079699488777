<template>
  <div :style="{ width: canvasWidth + 'px' }" id="belt_canvas">
    <canvas id="fabricbelt"></canvas>
  </div>
</template>

<script>
export default {
  props: ['canvasHeight', 'canvasWidth'],
  data() {
    return {
      viewCanvas: null,
      flagId: null,
      textId: null,
      flagWidth: 0,
      textWidth: 0
    }
  },
  methods: {
    generateID() {
      var id = Date.now()
      var oggettiInCanvas = this.viewCanvas.toJSON().objects
      oggettiInCanvas.forEach(oggetto => {
        if (oggetto.id === id) {
          return this.generateID()
        }
      })
      return id
    },
    addFlag(uri) {
      if (uri && uri !== '-') {
        this.addImage('/assets/flags/' + uri.toLowerCase() + '.svg').then(id => {
          if (this.flagId) {
            this.removeFabricObject(this.flagId)
          }
          this.flagId = id
          if (this.isUnderwear) this.updateFlagAndTextPosition()
        })
      } else {
        this.removeFabricObject(this.flagId)
        this.flagId = null
      }
    },
    addText(beltText, color) {
      var text = new fabric.Text(beltText, {
        left: this.canvasValues.textX,
        top: this.canvasValues.textY,
        fontSize: 16,
        fontFamily: 'Open Sans',
        fill: color,
        selectable: false
      })
      this.textWidth = Math.round(text.width)
      if (this.textWidth > this.canvasValues.maxWidth) {
        text.scaleToWidth(this.canvasValues.maxWidth)
        this.textWidth = this.canvasValues.maxWidth
        text.top = text.top + 4
      }
      //estendo l'oggetto con il nome e l'id
      text.toObject = (function(toObject) {
        return function() {
          return fabric.util.object.extend(toObject.call(this), {
            id: this.id
          })
        }
      })(text.toObject)
      //aggiungo l'id
      text.id = this.generateID().toString()
      if (this.textId) {
        this.removeFabricObject(this.textId)
      }
      this.textId = text.id
      this.viewCanvas.add(text)
      this.isUnderwear ? this.updateFlagAndTextPosition() : this.viewCanvas.renderAll()
    },
    addImage(uri) {
      return new Promise(resolve => {
        //creo l'immagine
        this.$store.commit('CONFIGURATOR_IS_FETCHING', true)
        var imgObj = new Image()
        imgObj.src = uri
        imgObj.onload = () => {
          this.$store.commit('CONFIGURATOR_IS_FETCHING', false)
          var image = new fabric.Image(imgObj, {
            selectable: false,
            transparentCorners: true
          })
          image.scaleToWidth(this.flagWidth)
          image.left = this.canvasValues.flagX
          image.top = this.canvasValues.flagY - image.getScaledHeight() / 2

          //estendo l'oggetto con il nome e l'id
          image.toObject = (function(toObject) {
            return function() {
              return fabric.util.object.extend(toObject.call(this), {
                id: this.id
              })
            }
          })(image.toObject)
          //aggiungo l'id
          image.id = this.generateID().toString()
          //render props
          this.viewCanvas.add(image)
          //this.viewCanvas.centerObject(image)
          this.viewCanvas.renderAll()

          resolve(image.id)
        }
        //
      })
      //
    },
    updateFlagAndTextPosition() {
      let objects = this.viewCanvas.getObjects()

      let flagObj = objects.find(o => o.id === this.flagId)
      let textObj = objects.find(o => o.id === this.textId)

      if (flagObj) {
        flagObj.left = (this.viewCanvas.getWidth() - this.flagAndTextWidth) / 2
      }
      if (textObj) {
        textObj.left =
          (this.viewCanvas.getWidth() - this.textWidth) / 2 +
          (flagObj ? (this.canvasValues.textX - this.canvasValues.flagX - this.flagWidth) * 2 : 0)
      }

      this.viewCanvas.renderAll()
    },
    removeFabricObject(id) {
      var objects = this.viewCanvas.getObjects()
      for (const key in objects) {
        if (objects[key].id === id) {
          this.viewCanvas.remove(objects[key])
          return
        }
      }
    },
    export() {
      return this.viewCanvas.toJSON()
    },
    import(object) {
      this.viewCanvas.loadFromJSON(object)
      this.viewCanvas.renderAll()
    },
    updateValues(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.addText(this.name + '  ' + this.bloodType, this.$store.getters.getColorByMaterial(this.color))
      }
    }
  },
  mounted() {
    const container = document.querySelector('.main-view')
    this.viewCanvas = new fabric.Canvas('fabricbelt', {
      width: container.clientWidth,
      height: container.clientHeight,
      selection: false,
      hoverCursor: 'default'
    })
    this.flagWidth = this.canvasValues.flagWidth || 55
    this.addText(this.name + '  ' + this.bloodType, this.$store.getters.getColorByMaterial(this.color))
    this.addFlag(this.country)
  },
  computed: {
    canvasValues() {
      return this.$store.state.configurator.actualModel.belt.canvas
    },
    country() {
      return this.$store.state.configurator.actualModel.belt.country
    },
    name() {
      return this.$store.state.configurator.actualModel.belt.name
    },
    font() {
      return this.$store.state.configurator.actualModel.belt.font
    },
    bloodType() {
      return this.$store.state.configurator.actualModel.belt.bloodType
    },
    color() {
      return this.$store.state.configurator.actualModel.belt.selectedMaterial
    },
    flagAndTextWidth() {
      return this.flagWidth + this.textWidth
    },
    isUnderwear() {
      return this.$store.state.configurator.actualModel.type === 'underwear'
    }
  },
  watch: {
    country(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.addFlag(newVal)
      }
    },
    name(newVal, oldVal) {
      this.updateValues(newVal, oldVal)
    },
    font(newVal, oldVal) {
      this.updateValues(newVal, oldVal)
    },
    bloodType(newVal, oldVal) {
      this.updateValues(newVal, oldVal)
    },
    color(newVal, oldVal) {
      this.updateValues(newVal, oldVal)
    }
  }
}
</script>
<style>
#belt_canvas {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
