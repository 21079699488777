<template>
  <div class="design">
    <!-- riga dei modelli -->
    <div class="row">
      <div class="col-md-6">
        <h2>{{ $t('CFG_DESIGN_TITLE_MODEL') }}</h2>
      </div>
    </div>
    <div class="row">
      <div
        class="col-md-3 model no-select"
        :class="{ 'cursor-pointer': model.sku !== $store.state.configurator.actualModel.sku }"
        v-for="(model, index) in $store.state.configurator.models"
        :key="index"
        @click="selectModel(model.sku)"
      >
        <div class="square-container">
          <div class="square-content">
            <SVGViewer
              class="py-2"
              :viewData="{
                asset: model.preview.views.front,
                areas: model.preview.areas,
                transformation: null
              }"
              :nascar="configuratorNascarCuffs"
              :selected="model.sku === $store.state.configurator.actualModel.sku"
              :belt="configuratorBelt.show"
              :beltArea="model.preview.beltArea"
              :pocket="configuratorPocket"
            />
          </div>
        </div>
        <p
          class="text-center mt-2 mb-3"
          :class="{ 'text-active': model.sku === $store.state.configurator.actualModel.sku }"
        >
          {{ model.name }}
        </p>
      </div>
    </div>
    <div class="row mt-3 mt-xl-2" v-if="$store.state.configurator.actualModel.description">
      <div class="col-12">
        <p class="model-description">
          <span v-html="$t($store.state.configurator.actualModel.description)"></span>
          <span
            class="mt-2"
            v-if="
              (!$route.meta.b2c && loggedUser && loggedUser.hideDescrPrice !== true) ||
                ($route.meta.b2c && b2cDealer && b2cDealer.hideDescrPrice !== true)
            "
            >{{ $t('CFG_DESIGN_PRICE_FROM') }} {{ $store.state.configurator.basePrice[userCompany] | price }}</span
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import SVGViewer from '@/components/configurator/SVGViewer.vue'

export default {
  components: {
    SVGViewer
  },
  computed: {
    ...mapGetters([
      'configuratorBelt',
      'configuratorPocket',
      'configuratorNascarCuffs',
      'userCompany',
      'loggedUser',
      'b2cDealer'
    ])
  },
  methods: {
    selectModel(model) {
      if (model !== this.$store.state.configurator.actualModel.sku) {
        this.$store.dispatch('CONFIGURATOR_SELECT_MODEL', model).then(() => {
          this.$store.commit('CONFIGURATOR_CHANGE_SIZETYPE', { type: 'standard' })
        })
      }
    }
  }
}
</script>

<style lang="scss">
.button-custom {
  color: $c-white;
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid $c-white;
  padding: 10px;
  width: 40%;
  box-sizing: border-box;
  margin-right: 20px;
  @include only-xl() {
    width: 30%;
  }
}

.button-selected {
  background-color: $c-yellow;
  border: 2px solid $c-yellow;
}

.box-active {
  background-color: $c-yellow !important;
}

.text-active {
  color: $c-yellow;
  text-transform: capitalize;
  font-weight: bold;
}

.model {
  font-size: 14px;

  @include only-xl() {
    font-size: 18px;
  }

  .overlay {
    padding-top: 70%;
    width: 100%;
    height: 100%;
    background-color: $c-white;
  }
}

.overlay {
  padding-top: 100%;
  width: 100%;
  height: 100%;
  background-color: $c-white;
}

.model-img {
  width: 100px;
}

.model-description {
  font-size: 15px;
  margin: 0;
  padding: 0;
  @include only-xl() {
    font-size: 16px;
  }
  span {
    display: block;
  }
}
</style>
