var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"colorpart"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('h2',[_vm._v(_vm._s(_vm.$t('CFG_COLOR_TITLE_AREA')))])])]),_c('div',{staticClass:"row"},_vm._l((_vm.areas),function(area,index){return _c('div',{key:index,staticClass:"col-6 col-xl-3 mb-3"},[_c('div',{staticClass:"color-tag d-flex flex-row",class:{
          selected: index === _vm.configuratorSelectedColorArea,
          'cursor-pointer': index !== _vm.configuratorSelectedColorArea
        },on:{"click":function($event){return _vm.$store.commit('CONFIGURATOR_SELECT_COLOR_AREA', index)}}},[_c('div',{staticClass:"color-preview",style:({ 'background-color': _vm.getColorByMaterial(area.selectedMaterial) })}),_c('p',[_vm._v(_vm._s(_vm.$t(area.name)))])])])}),0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('h2',[_vm._v(_vm._s(_vm.$t('CFG_COLOR_TITLE_COLOR')))])])]),_c('div',{staticClass:"row"},_vm._l((_vm.areas[_vm.configuratorSelectedColorArea].availableMaterials),function(material,index){return _c('div',{key:index,staticClass:"col-color",on:{"click":function($event){return _vm.$store.commit('CONFIGURATOR_SET_COLOR', { area: _vm.configuratorSelectedColorArea, material: material })}}},[_c('div',{staticClass:"cell-color",class:{
          'color-selected': _vm.areas[_vm.configuratorSelectedColorArea].selectedMaterial === material,
          'cursor-pointer': _vm.areas[_vm.configuratorSelectedColorArea].selectedMaterial !== material
        },style:({ 'background-color': _vm.$store.getters.getColorByMaterial(material) })}),_c('p',{staticClass:"color-label text-center",class:{
          'color-label-selected': _vm.areas[_vm.configuratorSelectedColorArea].selectedMaterial === material
        }},[_vm._v(" "+_vm._s(_vm.$t(material))+" ")])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }