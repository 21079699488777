<template>
  <div>
    <button class="Button Button__create-outline" v-b-modal="'priceCTA'">
      {{ $t('ACTION_PRICE') }} <Calculator />
    </button>
    <b-modal id="priceCTA" ref="priceCTA" class="order-modal-price" centered hide-footer :title="$t('PRICE')">
      <div class="order-modal-price-body">
        <div>
          <div class="row mb-1">
            <div class="col-6 text-left">{{ $t('CFG_SUMMARY_CONFIGURATION') }}</div>
            <div class="col-6 text-right">{{ currentModelPrice() | price }}</div>
          </div>
          <div class="row mb-1" v-if="userCompany === 'USA'">
            <div class="col-6 text-left">{{ $t('CFG_SUMMARY_TABLE_NASCAR_CUFFS') }}</div>
            <div class="col-6 text-right" v-if="configuratorNascarCuffs">
              {{ nascarCuffsPrice() | price }}
            </div>
            <div class="col-6 text-right" v-if="!configuratorNascarCuffs">-</div>
          </div>
          <div class="row mb-1">
            <div class="col-6 text-left">{{ $t('CFG_SUMMARY_MEASURES') }}</div>
            <div class="col-6 text-right" v-if="!isCustomizedSize">-</div>
            <div class="col-6 text-right" v-if="isCustomizedSize">{{ sizePrice() | price }}</div>
          </div>
          <div class="row mb-3">
            <div class="col-6 text-left">
              {{ $t('CFG_SUMMARY_LOGOS') }}&nbsp;
              <span v-if="$store.state.configurator.currentLogoMode === 0">{{
                $t('CFG_SUMMARY_TABLE_LOGOS_MODE_PRINT')
              }}</span>
              <span v-if="$store.state.configurator.currentLogoMode === 1">{{
                $t('CFG_SUMMARY_TABLE_LOGOS_MODE_EMBROIDERY')
              }}</span>
            </div>
            <div class="col-6 text-right">
              {{ (logosPrice().logoSurface + logosPrice().digitalization).toFixed(2) | price }}
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-left">
              <strong>{{ $t('CFG_SUMMARY_PRICE') }}</strong>
            </div>
            <div class="col-6 text-right">
              <strong>{{ totalPrice() | price }}</strong>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Calculator from 'vue-material-design-icons/Calculator.vue'

export default {
  name: 'PriceCTA',
  components: {
    Calculator
  },
  computed: {
    ...mapGetters([
      'currentModelPrice',
      'nascarCuffsPrice',
      'configuratorNascarCuffs',
      'isCustomizedSize',
      'sizePrice',
      'logosPrice',
      'userCompany'
    ])
  },
  methods: {
    totalPrice() {
      return (
        this.currentModelPrice() +
        this.nascarCuffsPrice() +
        this.logosPrice().logoSurface +
        this.logosPrice().digitalization +
        this.sizePrice()
      ).toFixed(2)
    }
  }
}
</script>

<style scoped lang="scss">
.Button {
  height: 50px;
  width: 100%;
  font-weight: bold;
}
</style>
