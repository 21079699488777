<template>
  <div id="colorpart">
    <div class="row">
      <div class="col-md-6">
        <h2>{{ $t('CFG_COLOR_TITLE_AREA') }}</h2>
      </div>
    </div>
    <div class="row">
      <div v-for="(area, index) in areas" :key="index" class="col-6 col-xl-3 mb-3">
        <div
          class="color-tag d-flex flex-row"
          :class="{
            selected: index === configuratorSelectedColorArea,
            'cursor-pointer': index !== configuratorSelectedColorArea
          }"
          @click="$store.commit('CONFIGURATOR_SELECT_COLOR_AREA', index)"
        >
          <div class="color-preview" :style="{ 'background-color': getColorByMaterial(area.selectedMaterial) }"></div>
          <p>{{ $t(area.name) }}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h2>{{ $t('CFG_COLOR_TITLE_COLOR') }}</h2>
      </div>
    </div>
    <div class="row">
      <div
        class="col-color"
        v-for="(material, index) in areas[configuratorSelectedColorArea].availableMaterials"
        :key="index"
        @click="$store.commit('CONFIGURATOR_SET_COLOR', { area: configuratorSelectedColorArea, material: material })"
      >
        <div
          class="cell-color"
          :class="{
            'color-selected': areas[configuratorSelectedColorArea].selectedMaterial === material,
            'cursor-pointer': areas[configuratorSelectedColorArea].selectedMaterial !== material
          }"
          :style="{ 'background-color': $store.getters.getColorByMaterial(material) }"
        ></div>
        <p
          class="color-label text-center"
          :class="{
            'color-label-selected': areas[configuratorSelectedColorArea].selectedMaterial === material
          }"
        >
          {{ $t(material) }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['configuratorSelectedColorArea', 'getColorByMaterial'])
  },
  props: {
    areas: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
.color-preview {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.color-tag {
  background-color: $c-grey2;
  color: $c-blue;
  padding: 5px;
  @include only-xl() {
    padding: 10px;
  }
}

.selected {
  background-color: $c-yellow;
  font-weight: bold;
}

.color-selected {
  transform: scale(0.9);
  background-image: url(../../assets/check.png);
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
}

.color-label {
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 10px;
}

.color-label-selected {
  font-weight: bold;
  color: $c-yellow;
}

.col-color {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 11.1%;
  @include only-l() {
    width: 16.6%;
  }
}
</style>
