<template>
  <div class="belt_overview_pdf" style="position: absolute;">
    <div
      class="canvas_frame"
      :style="`margin-left: ${beltPdfValues.offsetLeft || 0}; margin-top: ${beltPdfValues.offsetTop || 0}`"
    >
      <img v-if="beltPng" :src="beltPng" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    currentView: String
  },
  data() {
    return {
      fabricCanvas: null,
      beltPng: ''
    }
  },
  methods: {
    addFlag(cb) {
      if (this.beltConfig.country) {
        const imgObj = new Image()
        imgObj.onload = () => {
          const image = new fabric.Image(imgObj, {
            selectable: false,
            transparentCorners: true
          })
          image.scaleToWidth(15)
          image.left = 0
          image.top = 0
          this.fabricCanvas.add(image)
          this.addText(cb)
        }
        imgObj.src = '/assets/flags/' + this.beltConfig.country.toLowerCase() + '.svg'
      }
    },
    addText(cb) {
      if (this.beltText && this.beltConfig.selectedMaterial) {
        const text = new fabric.Text(this.beltText, {
          top: 1,
          left: 19,
          fontSize: 6,
          fontFamily: 'Open Sans',
          fill: this.$store.getters.getColorByMaterial(this.beltConfig.selectedMaterial),
          selectable: false
        })
        if (Math.round(text.width) > this.beltPdfValues.maxWidth) {
          text.scaleToWidth(this.beltPdfValues.maxWidth)
          text.top = text.top + 2
        }
        this.fabricCanvas.add(text)
        cb()
      }
    },
    renderPNG() {
      this.fabricCanvas.clear()
      this.beltPng = ''
      if (this.beltConfig.country) {
        this.addFlag(() => {
          this.fabricCanvas.renderAll()
          this.beltPng = this.fabricCanvas.toDataURL('png')
        })
      } else {
        this.addText(() => {
          this.fabricCanvas.renderAll()
          this.beltPng = this.fabricCanvas.toDataURL('png')
        })
      }
    }
  },
  mounted() {
    let canvas = document.createElement('canvas')
    this.fabricCanvas = new fabric.Canvas(canvas, {
      width: 120,
      height: 20
    })
    this.renderPNG()
  },
  computed: {
    currentModel() {
      return this.$store.state.configurator.actualModel
    },
    currentStyle() {
      return this.$store.state.configurator.actualStyle
    },
    beltConfig() {
      return this.$store.state.configurator.actualModel ? this.$store.state.configurator.actualModel.belt : {}
    },
    beltText() {
      if (this.beltConfig.name && this.beltConfig.bloodType) {
        return this.beltConfig.name + '  ' + this.beltConfig.bloodType
      } else if (this.beltConfig.bloodType && !this.beltConfig.name) {
        return this.beltConfig.bloodType
      }
      return this.beltConfig.name
    },
    beltPdfValues() {
      return this.$store.state.configurator.actualModel &&
        this.$store.state.configurator.actualModel.belt &&
        this.$store.state.configurator.actualModel.belt.pdf
        ? this.$store.state.configurator.actualModel.belt.pdf
        : {}
    }
  },
  watch: {
    currentModel() {
      this.renderPNG()
    },
    beltConfig: {
      deep: true,
      handler() {
        this.renderPNG()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.belt_overview_pdf {
  width: 100%;
  height: 100%;
  pointer-events: none !important;

  .canvas_frame {
    position: absolute;
  }
}
</style>
