<template>
  <div class="ErrorMessage">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="text-center">
            <div>
              <Alert />
            </div>
            <div class="ErrorMessage__Message">{{ $t(message || 'ERROR_GENERIC') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from 'vue-material-design-icons/Alert.vue'

export default {
  name: 'ErrorMessage',
  props: {
    message: {
      type: String
    }
  },
  components: { Alert }
}
</script>

<style scoped lang="scss">
.ErrorMessage {
  color: $c-error;
  font-size: 25px;

  .material-design-icon {
    font-size: 100px;
  }
}
</style>
