var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"design"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('h2',[_vm._v(_vm._s(_vm.$t('CFG_DESIGN_TITLE_STYLE')))])])]),_c('div',{staticClass:"row"},_vm._l((_vm.$store.state.configurator.actualModel.styles),function(style,index){return _c('div',{key:index,staticClass:"col-3 col-xl-2 style no-select",class:{ 'cursor-pointer': index !== _vm.$store.state.configurator.actualStyle },on:{"click":function($event){return _vm.selectStyle(index)}}},[_c('div',{staticClass:"square-container"},[_c('div',{staticClass:"square-content"},[_c('SVGViewer',{staticClass:"py-2",attrs:{"viewData":{
              asset: style.views.front,
              areas: _vm.defaultStyleAreas(index),
              transformation: null
            },"nascar":_vm.configuratorNascarCuffs,"selected":index === _vm.$store.state.configurator.actualStyle,"belt":_vm.configuratorBelt.show,"beltArea":style.beltArea,"pocket":_vm.configuratorPocket}})],1)]),_c('p',{staticClass:"text-center mx-2",class:{ 'text-active': index === _vm.$store.state.configurator.actualStyle }},[_vm._v(" "+_vm._s(style.name)+" ")])])}),0),_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.configuratorIsPocketAvailable),expression:"configuratorIsPocketAvailable"}],staticClass:"col-md-6 col-lg-4"},[_c('h2',[_vm._v(_vm._s(_vm.$t('CFG_DESIGN_TITLE_POCKET')))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-6"},[_c('button',{staticClass:"button-custom",class:{ 'button-selected': _vm.configuratorPocket, 'cursor-pointer': !_vm.configuratorPocket },on:{"click":function($event){return _vm.setPocket(true)}}},[_vm._v(" "+_vm._s(_vm.$t('YES'))+" ")])]),_c('div',{staticClass:"col-12 mt-1 col-xl-6 mt-xl-0"},[_c('button',{staticClass:"button-custom",class:{ 'button-selected': !_vm.configuratorPocket, 'cursor-pointer': _vm.configuratorPocket },on:{"click":function($event){return _vm.setPocket(false)}}},[_vm._v(" "+_vm._s(_vm.$t('NO'))+" ")])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.configuratorIsNavigatorPocketAvailable),expression:"configuratorIsNavigatorPocketAvailable"}],staticClass:"col-md-6 col-lg-5 mt-md-2 mt-lg-0"},[_c('h2',[_vm._v(_vm._s(_vm.$t('CFG_DESIGN_TITLE_NAVIGATOR_POCKET')))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-6"},[_c('button',{staticClass:"button-custom",class:{
              'button-selected': _vm.configuratorNavigatorPocket,
              'cursor-pointer': !_vm.configuratorNavigatorPocket
            },on:{"click":function($event){return _vm.setNavigatorPocket(true)}}},[_vm._v(" "+_vm._s(_vm.$t('YES'))+" ")])]),_c('div',{staticClass:"col-12 mt-1 col-xl-6 mt-xl-0"},[_c('button',{staticClass:"button-custom",class:{
              'button-selected': !_vm.configuratorNavigatorPocket,
              'cursor-pointer': _vm.configuratorNavigatorPocket
            },on:{"click":function($event){return _vm.setNavigatorPocket(false)}}},[_vm._v(" "+_vm._s(_vm.$t('NO'))+" ")])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.configuratorIsBeltAvailable),expression:"configuratorIsBeltAvailable"}],staticClass:"col-md-6 col-lg-3"},[_c('h2',[_vm._v(_vm._s(_vm.$t('CFG_DESIGN_TITLE_BELT')))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-6"},[_c('button',{staticClass:"button-custom",class:{
              'button-selected': _vm.configuratorBelt && _vm.configuratorBelt.show,
              'cursor-pointer': !_vm.configuratorBelt || !_vm.configuratorBelt.show
            },on:{"click":function($event){return _vm.setBelt(true)}}},[_vm._v(" "+_vm._s(_vm.$t('YES'))+" ")])]),_c('div',{staticClass:"col-12 mt-1 col-xl-6 mt-xl-0"},[_c('button',{staticClass:"button-custom",class:{
              'button-selected': !_vm.configuratorBelt || !_vm.configuratorBelt.show,
              'cursor-pointer': _vm.configuratorBelt && _vm.configuratorBelt.show
            },on:{"click":function($event){return _vm.setBelt(false)}}},[_vm._v(" "+_vm._s(_vm.$t('NO'))+" ")])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.configuratorAreNascarCuffsAvailable),expression:"configuratorAreNascarCuffsAvailable"}],staticClass:"col-md-6 col-lg-4 mt-md-2",class:_vm.configuratorIsNavigatorPocketAvailable ? 'mt-lg-2' : 'mt-lg-0'},[_c('h2',[_vm._v(_vm._s(_vm.$t('CFG_DESIGN_TITLE_NASCAR_CUFFS')))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-6"},[_c('button',{staticClass:"button-custom",class:{ 'button-selected': _vm.configuratorNascarCuffs, 'cursor-pointer': !_vm.configuratorNascarCuffs },on:{"click":function($event){return _vm.setNascarCuffs(true)}}},[_vm._v(" "+_vm._s(_vm.$t('YES'))+" ")])]),_c('div',{staticClass:"col-12 mt-1 col-xl-6 mt-xl-0"},[_c('button',{staticClass:"button-custom",class:{ 'button-selected': !_vm.configuratorNascarCuffs, 'cursor-pointer': _vm.configuratorNascarCuffs },on:{"click":function($event){return _vm.setNascarCuffs(false)}}},[_vm._v(" "+_vm._s(_vm.$t('NO'))+" ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }