<template>
  <div id="text_for_belt">
    <!-- CONF cintura -->
    <div class="row">
      <div class="col-md-6">
        <h2>{{ $t('CFG_TEXT_TITLE_CUSTOMIZE') }}</h2>
      </div>
    </div>
    <div class="row">
      <!-- tutte le impostazioni che hanno una select -->
      <div
        v-for="(elem, index) in config[$store.getters.userCompany]"
        :key="index"
        :class="{ 'col-md-12': $store.getters.userCompany === 'USA', 'col-md-6': $store.getters.userCompany === 'ITA' }"
      >
        <label>{{ $t(elem.label) }}</label>
        <b-form-select :options="elem.options" required v-model="elem.selected" @input="onSelectChange(elem)">
        </b-form-select>
      </div>
    </div>
    <div class="row">
      <!-- tutte le impostazioni che hanno il testo (per ora solo il nome) -->
      <div class="col-12">
        <label>{{ $t('CFG_TEXT_PLACEHOLDER_NAME') }}</label>
        <b-form-input
          type="text"
          class="text-center"
          maxlength="15"
          v-model="text.value"
          required
          placeholder="-"
          :formatter="formatter"
          @input="onInputChange(text)"
        >
        </b-form-input>
      </div>
    </div>
    <!-- colori cintura -->
    <div class="row">
      <div class="col-md-6">
        <h2>{{ $t('CFG_TEXT_TITLE_COLOR') }}</h2>
      </div>
    </div>
    <div class="row">
      <div
        class="col-color"
        v-for="(elem, index) in $store.state.configurator.actualModel.belt.availableMaterials"
        :key="index"
        @click="$store.commit('CONFIGURATOR_SET_BELT', { option: 'selectedMaterial', value: elem })"
      >
        <div
          class="cell-color"
          :style="{ 'background-color': $store.getters.getColorByMaterial(elem) }"
          :class="{
            'color-selected': elem === $store.state.configurator.actualModel.belt.selectedMaterial,
            'cursor-pointer': elem !== $store.state.configurator.actualModel.belt.selectedMaterial
          }"
        ></div>
        <p
          class="color-label text-center"
          :class="{ 'color-label-selected': elem === $store.state.configurator.actualModel.belt.selectedMaterial }"
        >
          {{ $t(elem) }}
        </p>
      </div>
    </div>
    <div class="row warning">
      <div class="col">
        <h2><Alert /> {{ $t('CFG_TEXT_WARNING_TITLE') }}:</h2>
        &nbsp;
        <p>{{ $t('CFG_TEXT_WARNING_TEXT') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import Alert from 'vue-material-design-icons/Alert.vue'
import countries from '@/assets/countries.json'

export default {
  data() {
    // Sort countries by company
    if (this.$store.getters.userCompany === 'USA') {
      countries.sort((x, y) => (x.alpha3Code === 'USA' ? -1 : y.alpha3Code === 'USA' ? 1 : 0))
    }

    return {
      //text inputs
      text: {
        //nome del campo nel datamodel (vuex)
        dataModel: 'name',
        value: ''
      },
      //select inputs
      config: {
        USA: [
          {
            name: 'flag',
            label: 'CFG_TEXT_PLACEHOLDER_FLAG',
            dataModel: 'country',
            selected: null,
            options: [{ value: '', text: '-' }, ...countries.map(x => ({ value: x.alpha3Code, text: x.name }))]
          }
        ],
        ITA: [
          {
            name: 'flag',
            label: 'CFG_TEXT_PLACEHOLDER_FLAG',
            dataModel: 'country',
            selected: null,
            options: [{ value: '', text: '-' }, ...countries.map(x => ({ value: x.alpha3Code, text: x.name }))]
          },
          {
            name: 'blood',
            label: 'CFG_TEXT_PLACEHOLDER_BLOOD',
            dataModel: 'bloodType',
            selected: null,
            options: [{ value: '', text: '-' }, '0+', '0-', 'A+', 'A-', 'AB+', 'AB-', 'B+', 'B-']
          }
        ]
      }
    }
  },
  components: {
    Alert
  },
  methods: {
    getFirstMaterial() {
      const materials = this.$store.state.configurator.actualModel.belt.availableMaterials
      const beltArea = this.$store.getters.configuratorBelt.beltArea
      const area = this.$store.getters.currentStyleAreas.find(x => x.name === beltArea)

      if (area && area.selectedMaterial && area.selectedMaterial.code) {
        return materials.filter(x => x !== area.selectedMaterial.code)[0]
      } else {
        return materials[0]
      }
    },
    onSelectChange(elem) {
      const belt = this.$store.getters.getBeltConfiguration
      this.$store.commit('CONFIGURATOR_SET_BELT', { option: elem.dataModel, value: elem.selected })
      if (elem.dataModel === 'bloodType' && elem.selected && !belt.selectedMaterial) {
        this.$store.commit('CONFIGURATOR_SET_BELT', { option: 'selectedMaterial', value: this.getFirstMaterial() })
      }
    },
    onInputChange(text) {
      const belt = this.$store.getters.getBeltConfiguration
      this.$store.commit('CONFIGURATOR_SET_BELT', { option: text.dataModel, value: text.value })
      if (text.value && !belt.selectedMaterial) {
        this.$store.commit('CONFIGURATOR_SET_BELT', { option: 'selectedMaterial', value: this.getFirstMaterial() })
      }
    },
    onResize() {
      const mainView = document.querySelector('.main-view')
      const mainViewArea = document.querySelector('.main-view-area')
      if (mainView && mainViewArea) {
        mainView.setAttribute(
          'style',
          `left: ${(mainViewArea.clientWidth - mainView.clientWidth) / 2}px;
           top: ${(mainViewArea.clientHeight - mainView.clientHeight) / 2}px`
        )
      }
      window.addEventListener('resize', this.onResize)
    },
    formatter(value) {
      return value.toUpperCase()
    }
  },
  mounted() {
    //ottengo le info della cintura
    const belt = this.$store.getters.getBeltConfiguration
    //bandiera
    this.config[this.$store.getters.userCompany][0].selected = belt.country
    //gruppo sanguigno
    if (this.$store.getters.userCompany === 'ITA' && this.config[this.$store.getters.userCompany][1]) {
      this.config[this.$store.getters.userCompany][1].selected = belt.bloodType
    }
    //nome
    this.text.value = belt.name

    //Set View
    const viewTransform = this.$store.getters.getBeltConfiguration.transform
    this.$store.commit('CONFIGURATOR_SET_VIEW_ANGLE', 'front')
    this.$store.commit('CONFIGURATOR_SET_VIEW_TRANSFORMATION', {
      scale: viewTransform.scale,
      position: { ...viewTransform.position }
    })

    const mainView = document.querySelector('.main-view')
    const mainViewArea = document.querySelector('.main-view-area')
    if (mainView && mainViewArea) {
      mainView.removeAttribute('style')
      mainView.setAttribute(
        'style',
        `left: ${(mainViewArea.clientWidth - mainView.clientWidth) / 2}px;
         top: ${(mainViewArea.clientHeight - mainView.clientHeight) / 2}px`
      )
    }
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    this.$store.commit('CONFIGURATOR_SET_VIEW_ANGLE', 'front')
    this.$store.commit('CONFIGURATOR_SET_VIEW_TRANSFORMATION', {})

    const mainView = document.querySelector('.main-view')
    if (mainView) {
      mainView.removeAttribute('style')
    }
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style scoped lang="scss">
.warning {
  h2 {
    border: none;
    margin: 0px;
    padding: 0;
    display: inline;
    font-size: 16px;
  }
  p {
    display: inline;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
}
.custom-select,
.form-control {
  background-color: rgba($c-white, 0);
  border-radius: 0;
  border: 2px solid $c-white !important;
  margin-bottom: 15px;
  color: $c-white !important;
  box-shadow: none;
  height: 40px !important;
  font-size: 18px;
}

.form-control::placeholder {
  color: $c-white !important;
}

.form-control:focus {
  background-color: $c-white !important;
  color: $c-blue !important;
}

.col-color {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 11.1%;
  @include only-l() {
    width: 16.6%;
  }
}
</style>
