<template>
  <div class="Loader">
    <svg class="spinner" width="66px" height="66px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style scoped lang="scss">
$offset: 187;
$duration: 1.4s;

.Loader {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.not-full-screen {
    position: initial;
    width: 66px;
    height: 66px;
  }

  .spinner {
    animation: rotator $duration linear infinite;
    position: absolute;
    width: 66px;
    height: 66px;
    top: 50%;
    margin-top: -33px;
    left: 50%;
    margin-left: -33px;
  }

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  .path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite, colors ($duration * 4) ease-in-out infinite;
  }

  @keyframes colors {
    0% {
      stroke: $c-blue;
    }
    33% {
      stroke: $c-white;
    }
    66% {
      stroke: $c-blue;
    }
    100% {
      stroke: $c-white;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: $offset;
    }
    50% {
      // stroke-dashoffset: $offset/4;
      stroke-dashoffset: calc($offset / 4);
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: $offset;
      transform: rotate(450deg);
    }
  }
}
</style>
