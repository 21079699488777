<template>
  <div class="decals_overview" :class="isUnderwear ? 'underwear' : ''">
    <div
      class="decals"
      v-if="images.upper"
      :class="isUnderwear ? 'underwear' : ''"
      :style="{
        height: `${isUnderwear ? imgHeight : parameters[currentView].height + '%'}`,
        width: `${isUnderwear ? imgWidth : '100%'}`,
        backgroundImage: `url(${images.upper})`,
        marginTop: parameters[currentView].top + `${isUnderwear ? '%' : 'vh'}`,
        backgroundSize: `${isUnderwear ? 'cover' : 'contain'}`,
        backgroundPosition: `${isUnderwear ? 'center' : 'top center'}`
      }"
    ></div>
    <div
      v-if="currentView === 'front' && images.lower"
      :style="{
        position: 'absolute',
        bottom: '0',
        height: '63%',
        transform: 'scale(1.33)',
        backgroundSize: 'contain !important',
        backgroundPosition: 'top center !important',
        backgroundImage: `url(${images.lower})`
      }"
      class="decals"
    ></div>
  </div>
</template>
<script>
export default {
  name: 'DecalsOverview',
  data() {
    return {
      imgWidth: '100%',
      imgHeight: '100%',
      images: {
        upper: null,
        lower: null
      }
    }
  },
  props: {
    currentView: {
      type: String,
      required: true
    }
  },
  methods: {
    render() {
      this.images.upper = null
      this.images.lower = null

      this.canvasConfig.forEach((x, i) => {
        if (x.config && x.config.canvas && x.config.objects.objects.length > 0) {
          let canvas = document.createElement('canvas')
          let fabricCanvas = new fabric.Canvas(canvas, {
            width: x.config.canvas.width,
            height: x.config.canvas.height
          })
          let objects = JSON.parse(JSON.stringify(x.config.objects.objects))
          for (let i in objects) {
            objects[i].scaleX = objects[i].scaleX
            objects[i].scaleY = objects[i].scaleY
            objects[i].left = objects[i].left
            objects[i].top = objects[i].top - 20
            objects[i].selectable = false
          }
          fabricCanvas.loadFromJSON({ objects: objects, version: x.config.version }, () => {
            if (i === 0) {
              this.images.upper = fabricCanvas.toDataURL('png')
            } else {
              this.images.lower = fabricCanvas.toDataURL('png')
            }
          })
        }
      })
    },
    getDimensions() {
      if (this.isUnderwear == true) {
        var svgObject = document.querySelector('.SVGViewer object').contentDocument
        var svg = svgObject.getElementById('area1')
        var rect = svg.getBoundingClientRect()
        this.imgWidth = `${rect.width}px`
        this.imgHeight = `${rect.height * 1.035}px`
      }
    }
  },
  mounted() {
    if (this.isUnderwear == true) {
      setTimeout(() => {
        this.getDimensions()
      }, 300)

      addEventListener('resize', () => {
        this.getDimensions()
      })
    }
    this.$nextTick(() => {
      this.render()
    })
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions)
  },
  computed: {
    currentModel() {
      return this.$store.state.configurator.actualModel
    },
    currentStyle() {
      return this.$store.state.configurator.actualStyle
    },
    canvasConfig() {
      return this.$store.state.configurator.actualModel.styles[
        this.$store.state.configurator.actualStyle
      ].customizedLogo.filter(elem => elem.view === this.currentView)
    },
    isUnderwear() {
      return this.currentModel.type && this.currentModel.type === 'underwear'
    },
    parameters() {
      return this.isUnderwear
        ? {
            front: {
              height: 100,
              top: 5.5
            },
            back: {
              height: 100,
              top: 2.5
            },
            left: {
              height: 100,
              top: 5.5
            },
            right: {
              height: 100,
              top: 5.5
            }
          }
        : {
            front: {
              height: 46,
              top: -2
            },
            back: {
              height: 46,
              top: -2
            },
            left: {
              height: 46,
              top: 2.4
            },
            right: {
              height: 46,
              top: 2.4
            }
          }
    }
  },
  watch: {
    currentModel() {
      this.render()
    },
    currentStyle() {
      this.render()
    },
    currentView() {
      this.$nextTick(() => {
        this.render()
      })
    },
    canvasConfig: {
      deep: true,
      handler() {
        this.render()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.decals_overview {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 70%;
  left: 15%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &.underwear {
    justify-content: center;
  }

  .decals {
    width: 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    background-position: center;
    /*&.underwear {
       transform: scale(0.72) !important;
      @media (max-width: 1024px) {
        transform: scale(0.56) !important;
      } 
    }*/
  }
}
</style>
