<template>
  <div id="sizecomp">
    <div class="row">
      <div class="col-6">
        <h2>{{ $t('CFG_SIZE_TITLE_TYPE') }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <button
          class="btn-outline"
          @click="changeSizeType('standard')"
          :class="{
            'btn-outline-selected': actualSize.type === 'standard',
            'cursor-pointer': actualSize.type !== 'standard'
          }"
        >
          {{ $t('CFG_SIZE_STANDARD') }}
        </button>
      </div>
      <div class="col-md-6" v-show="configuratorIsCustomSizeAvailable">
        <button
          class="btn-outline cursor-pointer"
          @click="changeSizeType('customized')"
          :class="{
            'btn-outline-selected': actualSize.type === 'customized',
            'cursor-pointer': actualSize.type !== 'customized'
          }"
        >
          {{ $t('CFG_SIZE_CUSTOMIZED') }}
        </button>
      </div>
    </div>
    <!-- selezione della taglia -->
    <!-- standard -->
    <div class="standard" v-if="actualSize.type === 'standard'">
      <div class="row">
        <div class="col-6">
          <h2>{{ $t('CFG_SIZE_TITLE_STANDARD') }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-6">
          <b-form-select
            :value="actualSize.measure"
            @input="$store.commit('CONFIGURATOR_CHANGE_STANDARDSIZE', $event)"
            :options="configuratorStandardSizes.map(x => ({ value: x, text: x + $t('CFG_SIZE_STD_SUFFIX_' + x) }))"
            class="mb-3"
          />
        </div>
      </div>
      <!-- Reolazioni standard solo per ITA -->
      <div class="row" v-if="userCompany === 'ITA' && configuratorIsCustomSizeAvailable">
        <div class="col-12 col-xl-6">
          <h2 class="hasTooltip">
            {{ $t('CFG_SIZE_TITLE_REGULATE') }}
            <toggle-button
              :value="isStandardSizeCustomized"
              @change="onChangeStandardToggle"
              :color="{ checked: '#ffcf00', unchecked: '#fff' }"
              :switchColor="{ checked: '#001489', unchecked: '#001489' }"
              :width="70"
              :height="30"
            />
          </h2>
        </div>
      </div>
      <div class="row" v-if="userCompany === 'ITA' && configuratorIsCustomSizeAvailable">
        <div class="col-12 mb-2">
          <Alert /> <span class="size-tooltip">{{ $t('CFG_SIZE_TITLE_REGULATE_NOTE') }}</span>
        </div>
      </div>
      <div class="row" v-if="isStandardSizeCustomized && configuratorIsCustomSizeAvailable">
        <div class="col-12 input-col">
          <div class="container-fluid pt-3">
            <div class="row">
              <div class="col-6 input-regulate" v-for="(elem, index) in actualSize.measures" :key="index">
                <label class="cursor-pointer"
                  >{{ $t(elem.name) }}
                  <button class="info cursor-pointer" v-if="elem.hint" v-b-modal="'modalSizeStd' + index">
                    <InformationOutline :title="$t('INFO_TOOLTIP')" />
                  </button>
                </label>
                <b-modal
                  v-if="elem.hint"
                  :id="'modalSizeStd' + index"
                  class="modalSize"
                  :title="$t(elem.name)"
                  centered
                  hide-footer
                >
                  <div class="row">
                    <div class="col-12 hint-col" :class="{ 'pt-0': !elem.hint.img }">
                      <img class="hint-img" v-if="elem.hint.img" :src="elem.hint.img" />
                      <p>{{ $t(elem.hint.text) }}</p>
                    </div>
                  </div>
                </b-modal>
                <div class="row">
                  <div class="col-4 col-xl-3">
                    <button
                      class="input-regulate-btn"
                      :class="{
                        disabled: Number(elem.value) === elem.exceptions.min,
                        'cursor-pointer': Number(elem.value) !== elem.exceptions.min
                      }"
                      @click="decrementSizeValue(elem)"
                    >
                      <Minus />
                    </button>
                  </div>
                  <div class="col-4 col-xl-3">
                    <span class="input-regulate-label">{{ elem.value }} {{ elem.unit.ITA }}</span>
                  </div>
                  <div class="col-4 col-xl-3">
                    <button
                      class="input-regulate-btn"
                      :class="{
                        disabled: Number(elem.value) === elem.exceptions.max,
                        'cursor-pointer': Number(elem.value) !== elem.exceptions.max
                      }"
                      @click="incrementSizeValue(elem)"
                    >
                      <Plus />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- customized -->
    <div class="customized" v-if="actualSize.type === 'customized'">
      <!-- SIZEYOU  -->
      <!-- <div class="row" v-if="userCompany === 'ITA'">
        <div class="col-md-6">
          <h2>
            {{ $t('CFG_SIZE_TITLE_GET_YOUR') }}

            <button tabindex="-1" class="info cursor-pointer" v-b-modal="'sizeyou-instructions'">
              <InformationOutline :title="$t('INFO_TOOLTIP')" />
            </button>
          </h2>
        </div>
      </div> -->
      <!-- <div class="row" v-if="userCompany === 'ITA'">
        <div class="col-6 text-center">
          <div class="i-size-you cursor-pointer" @click="openSizeYou()">
            <img :src="SizeYouB2B" alt="size you" v-if="!this.$route.meta.b2c" />
            <img :src="SizeYouB2C" alt="size you" v-if="this.$route.meta.b2c" />
          </div>
          <SizeYouFrame v-if="sizeYouFrameOpened" :onCloseFrame="() => (sizeYouFrameOpened = false)"></SizeYouFrame>
        </div>
        <b-modal
          id="sizeyou-instructions"
          class="modalSize modalSizeYou"
          title="SizeYou"
          centered
          hide-footer
          size="lg"
        >
          <div class="row">
            <div class="col-8 hint-col">
              <img :src="sizeYouInstructions" alt="SizeYou instructions" />
            </div>
            <div class="col-4 hint-col">
              <ul class="pl-0">
                <li class="pb-4">{{ $t('SIZEYOU_INSTRUCTIONS_1') }}</li>
                <li class="pb-4">{{ $t('SIZEYOU_INSTRUCTIONS_2') }}</li>
                <li>{{ $t('SIZEYOU_INSTRUCTIONS_3') }}</li>
              </ul>
            </div>
          </div>
        </b-modal>
      </div> -->

      <!-- GENDER SELECTOR  -->
      <div class="row" v-show="configuratorIsGenderAvailable">
        <div class="col-md-6">
          <button
            class="btn-outline cursor-pointer"
            @click="changeSizeGender('M')"
            :class="{
              'btn-outline-selected': sizeGender === 'M',
              'cursor-pointer': sizeGender !== 'M'
            }"
          >
            {{ $t('CFG_SIZE_GENDER_MALE') }}
          </button>
        </div>
        <div class="col-md-6">
          <button
            class="btn-outline cursor-pointer"
            @click="changeSizeGender('F')"
            :class="{
              'btn-outline-selected': sizeGender === 'F',
              'cursor-pointer': sizeGender !== 'F'
            }"
          >
            {{ $t('CFG_SIZE_GENDER_FEMALE') }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <h2>{{ $t('CFG_SIZE_TITLE_CUSTOMIZED') }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 input-col">
          <div class="container-fluid pt-3">
            <div class="row">
              <div v-for="(elem, index) in actualSize.measures" class="col-6" :key="index">
                <label class="cursor-pointer" tabindex="-1"
                  >{{ $t(elem.name) }}
                  <button
                    tabindex="-1"
                    class="info cursor-pointer"
                    v-if="elem.hint"
                    v-b-modal="'modalSizeCustom' + index"
                  >
                    <InformationOutline :title="$t('INFO_TOOLTIP')" />
                  </button>
                </label>
                <b-modal
                  v-if="elem.hint"
                  :id="'modalSizeCustom' + index"
                  class="modalSize"
                  :title="$t(elem.name)"
                  centered
                  hide-footer
                >
                  <div class="row">
                    <div class="col-12 hint-col" :class="{ 'pt-0': !elem.hint.img }">
                      <img class="hint-img" v-if="elem.hint.img" :src="elem.hint.img" />
                      <p>{{ $t(elem.hint.text) }}</p>
                    </div>
                  </div>
                </b-modal>
                <input type="number" class="form-control" v-model="elem.value" :placeholder="elem.unit[userCompany]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Alert from 'vue-material-design-icons/Alert.vue'
import Plus from 'vue-material-design-icons/Plus.vue'
import Minus from 'vue-material-design-icons/Minus.vue'
import InformationOutline from 'vue-material-design-icons/InformationOutline.vue'
// import SizeYouFrame from './SizeYouFrame'
import SizeYouB2B from '@/assets/sizeyou_b2b.jpg'
import SizeYouB2C from '@/assets/sizeyou_b2c.jpg'
import sizeYouInstructions from '@/assets/sizeyou-instructions.gif'

export default {
  components: {
    Alert,
    Plus,
    Minus,
    InformationOutline
    // SizeYouFrame
  },
  data() {
    return {
      sizeYouFrameOpened: false,
      sizeType: 'standard',
      sizeGender: '',
      min: 1,
      max: 300,
      SizeYouB2B,
      SizeYouB2C,
      sizeYouInstructions
    }
  },
  computed: {
    ...mapGetters([
      'configuratorStandardSizes',
      'configuratorIsCustomSizeAvailable',
      'actualSize',
      'isStandardSizeCustomized',
      'userCompany',
      'sizeYouHasParams',
      'sizeYouParams',
      'configuratorIsGenderAvailable',
      'configuratorDefaultGender'
    ])
  },
  methods: {
    decrementSizeValue(elem) {
      const currentValue = Number(elem.value)
      if (elem.exceptions && elem.exceptions.min) {
        if (currentValue - 1 >= Number(elem.exceptions.min)) {
          elem.value = currentValue - 1
        }
      } else {
        elem.value = currentValue - 1
      }
    },
    incrementSizeValue(elem) {
      const currentValue = Number(elem.value)
      if (elem.exceptions && elem.exceptions.max) {
        if (currentValue + 1 <= Number(elem.exceptions.max)) {
          elem.value = currentValue + 1
        }
      } else {
        elem.value = currentValue + 1
      }
    },
    //metodo che cambia da standard a customized
    changeSizeType(type) {
      if (type !== this.sizeType) {
        this.$store.commit('CONFIGURATOR_CHANGE_SIZETYPE', { type })
        this.sizeType = type
      }
    },
    changeSizeGender(gender) {
      if (gender !== this.sizeGender) {
        this.$store.commit('CONFIGURATOR_CHANGE_SIZEGENDER', gender)
        this.sizeGender = gender
      }
    },
    //metodo che cambia i valori di una taglia
    setSize(input, element) {
      if (input >= (element.exceptions.min || this.min) && input <= (element.exceptions.max || this.max)) {
        this.$store.commit('CONFIGURATOR_SET_SIZE', { measure: element.name, value: input })
      } else {
        //input invalido
        this.$store.commit('CONFIGURATOR_SET_SIZE', {
          measure: element.name,
          value: parseInt(this.$refs[element.name][0].value)
        })
      }
    },
    onChangeStandardToggle(data) {
      this.$store.dispatch('CONFIGURATOR_SET_STDSIZE_CUSTOMIZATION', Boolean(data.value))
    },
    openSizeYou() {
      if (this.sizeYouHasParams) {
        this.sizeYouFrameOpened = true
      } else {
        const type = this.$route.meta.b2c ? 'b2c' : 'b2b'
        this.$store.dispatch('SIZEYOU_GET_PARAMS', type).then(() => {
          this.sizeYouFrameOpened = true
        })
      }
    },
    onMessageReceived(event) {
      if (
        event.origin === window.location.origin &&
        event.data &&
        event.data.type === 'SIZEYOU' &&
        event.data.measures
      ) {
        this.$store.dispatch('CONFIGURATOR_SET_SIZEYOU_MEASURES', event.data.measures)

        if (this.$route.meta.b2c && this.sizeYouParams && this.sizeYouParams.logoutUrl) {
          window.open(this.sizeYouParams.logoutUrl)
        }

        this.sizeYouFrameOpened = false
      }
    }
  },
  created() {
    if (!this.actualSize) {
      this.$store.commit('CONFIGURATOR_CHANGE_SIZETYPE', { type: 'standard' })
    } else {
      this.sizeType = this.actualSize.type
      if (this.configuratorIsGenderAvailable) {
        if (this.actualSize.gender) {
          this.sizeGender = this.actualSize.gender
        } else {
          this.changeSizeGender(this.configuratorDefaultGender)
        }
      }
    }
  },
  mounted() {
    window.addEventListener('message', this.onMessageReceived)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.onMessageReceived)
  }
}
</script>

<style scoped lang="scss">
.input-col {
  font-size: 16px;

  label {
    text-transform: uppercase;
  }
  input {
    margin-bottom: 20px;
  }

  .container-fluid {
    background-color: $c-darkblue;
  }
}

.hint-col {
  padding: 10px;
  color: $c-text;
  font-size: 16px;

  @include only-xl() {
    padding: 20px;
  }
}

.hint-img {
  width: 100%;
  margin-bottom: 10px;

  @include only-xl() {
    margin-bottom: 30px;
  }
}

input:focus {
  background-color: $c-yellow;
}

.info {
  background-color: rgba($c-white, 0);
  border: none;
  color: $c-white;
  font-size: 20px;
}

.i-size-you {
  background: #fff;
  padding: 5px 0;
  margin-bottom: 10px;
  width: 100%;
  height: 90px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  img {
    height: 100%;
  }
}

.soon-available {
  color: #aebd00;
  font-style: italic;
  font-weight: bold;
  font-size: 16px;
}

.input-regulate {
  width: 100%;
  margin-bottom: 15px;
}

.input-regulate-btn {
  background: #fff;
  border: 2px solid #fff;
  color: $c-blue;
  font-size: 18px;

  &.disabled {
    opacity: 0.5;
  }
}

.input-regulate-label {
  display: inline-block;
}
.standard {
  .custom-select,
  .form-control {
    background-color: rgba($c-white, 0);
    border-radius: 0;
    border: 2px solid $c-white !important;
    margin-bottom: 15px;
    color: $c-white !important;
    box-shadow: none;
    height: 40px !important;
    font-size: 18px;
  }

  .form-control:focus {
    background-color: $c-white !important;
    color: $c-blue !important;
  }

  h2 {
    overflow: hidden;
    &.hasTooltip {
      margin-bottom: 10px;
    }
  }

  .size-tooltip {
    padding-left: 8px;
    color: $c-grey;
    font-size: 14px;
    font-style: italic;
  }

  .vue-js-switch {
    float: right;
  }
}

.customized {
  .custom-select,
  .form-control {
    background-color: $c-grey2;
    border-radius: 0;
    border: 2px solid $c-grey2 !important;
    margin-bottom: 15px;
    color: $c-white !important;
    box-shadow: none;
    height: 35px !important;
    font-size: 14px;
  }

  .form-control {
    color: $c-blue !important;
    &:focus {
      background-color: $c-yellow !important;
      color: $c-blue !important;
      border: 2px solid $c-yellow !important;
    }
  }
}
</style>
