<template>
  <div class="design">
    <div class="row">
      <div class="col-md-6">
        <h2>{{ $t('CFG_DESIGN_TITLE_STYLE') }}</h2>
      </div>
    </div>
    <div class="row">
      <div
        class="col-3 col-xl-2 style no-select"
        :class="{ 'cursor-pointer': index !== $store.state.configurator.actualStyle }"
        v-for="(style, index) in $store.state.configurator.actualModel.styles"
        :key="index"
        @click="selectStyle(index)"
      >
        <div class="square-container">
          <div class="square-content">
            <SVGViewer
              class="py-2"
              :viewData="{
                asset: style.views.front,
                areas: defaultStyleAreas(index),
                transformation: null
              }"
              :nascar="configuratorNascarCuffs"
              :selected="index === $store.state.configurator.actualStyle"
              :belt="configuratorBelt.show"
              :beltArea="style.beltArea"
              :pocket="configuratorPocket"
            />
          </div>
        </div>
        <p class="text-center mx-2" :class="{ 'text-active': index === $store.state.configurator.actualStyle }">
          {{ style.name }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-4" v-show="configuratorIsPocketAvailable">
        <h2>{{ $t('CFG_DESIGN_TITLE_POCKET') }}</h2>
        <div class="row">
          <div class="col-12 col-xl-6">
            <button
              @click="setPocket(true)"
              class="button-custom"
              :class="{ 'button-selected': configuratorPocket, 'cursor-pointer': !configuratorPocket }"
            >
              {{ $t('YES') }}
            </button>
          </div>
          <div class="col-12 mt-1 col-xl-6 mt-xl-0">
            <button
              @click="setPocket(false)"
              class="button-custom"
              :class="{ 'button-selected': !configuratorPocket, 'cursor-pointer': configuratorPocket }"
            >
              {{ $t('NO') }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-5 mt-md-2 mt-lg-0" v-show="configuratorIsNavigatorPocketAvailable">
        <h2>{{ $t('CFG_DESIGN_TITLE_NAVIGATOR_POCKET') }}</h2>
        <div class="row">
          <div class="col-12 col-xl-6">
            <button
              @click="setNavigatorPocket(true)"
              class="button-custom"
              :class="{
                'button-selected': configuratorNavigatorPocket,
                'cursor-pointer': !configuratorNavigatorPocket
              }"
            >
              {{ $t('YES') }}
            </button>
          </div>
          <div class="col-12 mt-1 col-xl-6 mt-xl-0">
            <button
              @click="setNavigatorPocket(false)"
              class="button-custom"
              :class="{
                'button-selected': !configuratorNavigatorPocket,
                'cursor-pointer': configuratorNavigatorPocket
              }"
            >
              {{ $t('NO') }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3" v-show="configuratorIsBeltAvailable">
        <h2>{{ $t('CFG_DESIGN_TITLE_BELT') }}</h2>
        <div class="row">
          <div class="col-12 col-xl-6">
            <button
              @click="setBelt(true)"
              class="button-custom"
              :class="{
                'button-selected': configuratorBelt && configuratorBelt.show,
                'cursor-pointer': !configuratorBelt || !configuratorBelt.show
              }"
            >
              {{ $t('YES') }}
            </button>
          </div>
          <div class="col-12 mt-1 col-xl-6 mt-xl-0">
            <button
              @click="setBelt(false)"
              class="button-custom"
              :class="{
                'button-selected': !configuratorBelt || !configuratorBelt.show,
                'cursor-pointer': configuratorBelt && configuratorBelt.show
              }"
            >
              {{ $t('NO') }}
            </button>
          </div>
        </div>
      </div>

      <div
        class="col-md-6 col-lg-4 mt-md-2 "
        :class="configuratorIsNavigatorPocketAvailable ? 'mt-lg-2' : 'mt-lg-0'"
        v-show="configuratorAreNascarCuffsAvailable"
      >
        <h2>{{ $t('CFG_DESIGN_TITLE_NASCAR_CUFFS') }}</h2>
        <div class="row">
          <div class="col-12 col-xl-6">
            <button
              @click="setNascarCuffs(true)"
              class="button-custom"
              :class="{ 'button-selected': configuratorNascarCuffs, 'cursor-pointer': !configuratorNascarCuffs }"
            >
              {{ $t('YES') }}
            </button>
          </div>
          <div class="col-12 mt-1 col-xl-6 mt-xl-0">
            <button
              @click="setNascarCuffs(false)"
              class="button-custom"
              :class="{ 'button-selected': !configuratorNascarCuffs, 'cursor-pointer': configuratorNascarCuffs }"
            >
              {{ $t('NO') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import SVGViewer from '@/components/configurator/SVGViewer.vue'

export default {
  components: {
    SVGViewer
  },
  methods: {
    selectStyle(style) {
      if (style !== this.$store.state.configurator.actualStyle) {
        this.$store.commit('CONFIGURATOR_SELECT_STYLE', style)
      }
    },
    setPocket(bool) {
      this.$store.commit('CONFIGURATOR_SET_POCKET', bool)
    },
    setNavigatorPocket(bool) {
      this.$store.commit('CONFIGURATOR_SET_NAVIGATOR_POCKET', bool)
    },
    setBelt(bool) {
      this.$store.commit('CONFIGURATOR_TOGGLE_BELT', bool)
    },
    setNascarCuffs(bool) {
      this.$store.commit('CONFIGURATOR_SET_NASCAR_CUFFS', bool)
    }
  },
  computed: {
    ...mapGetters([
      'userCompany',
      'configuratorIsBeltAvailable',
      'configuratorBelt',
      'configuratorIsPocketAvailable',
      'configuratorIsNavigatorPocketAvailable',
      'configuratorPocket',
      'configuratorNavigatorPocket',
      'configuratorAreNascarCuffsAvailable',
      'configuratorNascarCuffs',
      'defaultStyleAreas'
    ])
  }
}
</script>

<style lang="scss">
.button-custom {
  color: $c-white;
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid $c-white;
  padding: 0px 5px;
  width: 100%;
  box-sizing: border-box;
  margin-right: 0px;
  @include only-xl() {
    padding: 5px;
  }
}

.button-selected {
  background-color: $c-yellow;
  border: 2px solid $c-yellow;
}

.box-active {
  background-color: $c-yellow !important;
}

.text-active {
  color: $c-yellow;
  text-transform: capitalize;
  font-weight: bold;
}

.overlay {
  padding-top: 100%;
  width: 100%;
  height: 100%;
  background-color: $c-white;
}

.model-img {
  width: 100px;
}
</style>
